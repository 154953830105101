import { gql } from 'graphql-request';
import { USER_IN_THE_LIST_FRAGMENT } from './userInTheList';

export const USER_FRAGMENT = gql`
  fragment user on User {
    ...userInTheList

    crm_company_id
    role
    allowedPermissions
  }

  ${USER_IN_THE_LIST_FRAGMENT}
`;
