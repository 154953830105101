import { gql } from 'graphql-request';

export const DOMAIN_NAMES = gql`
  query domainNames($grid: DomainGridInput) {
    domains(grid: $grid) {
      rows {
        id
        name
      }
    }
  }
`;
