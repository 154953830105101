import { gql } from 'graphql-request';

export const TEXT_ORDER_IN_THE_LIST_FRAGMENT = gql`
  fragment textOrderInTheList on TextOrder {
    id
    title
    updated_at
    created_at
    status
    text_type
    order_type
    url
    text_url
    planned_publication_date
    domain {
      id
      name
    }
  }
`;
