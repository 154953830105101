import { gql } from 'graphql-request';

import { TICKET_FRAGMENT } from '../fragments';

export const UPDATE_TICKET = gql`
  mutation updateTicket(
    $id: ID!
    $title: String
    $contentHtml: String
    $typeId: ID
    $statusId: ID
    $deadline: DateTime
    $start: DateTime
    $domainId: ID
  ) {
    updateTicket(
      id: $id
      title: $title
      contentHtml: $contentHtml
      typeId: $typeId
      statusId: $statusId
      deadline: $deadline
      start: $start
      domainId: $domainId
    ) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
