import { gql } from 'graphql-request';

import { TEXT_ORDER_IN_THE_LIST_FRAGMENT } from '../fragments';

export const UPDATE_TEXT_ORDER = gql`
  mutation updateTextOrder($id: ID!, $status: TextOrderStatusEnum!) {
    updateTextOrder(id: $id, status: $status) {
      ...textOrderInTheList
    }
  }
  ${TEXT_ORDER_IN_THE_LIST_FRAGMENT}
`;
