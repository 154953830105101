export enum TableKeys {
  TICKETS = 'tickets',
  USERS = 'users',
  DOMAINS = 'domains',
  MONITORS = 'monitors',
  TEAMS = 'teams',
  DOMAIN_TICKETS = 'domainTickets',
  LINK_BUILDING = 'linkBuilding',
  DOMAIN_LINK_BUILDING = 'domainLinkBuilding',
  DOMAIN_TEXT_ORDERS = 'domainTextOrders',
}
