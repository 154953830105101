export enum Paths {
  CONTACT = '/contact',
  USERS = '/users',
  SETTINGS = '/settings',
  SET_NEW_PASSWORD = '/set-new-password',
  MY_ACCOUNT = '/my-account',
  MY_ACCOUNT_SETTINGS = '/my-account/settings',
  INFO = '/info',
  DOMAINS = '/domains',
  ROLES = '/roles',
  ADD = '/add',
  VIEW = '/view',
  UPTIME_MONITOR = '/uptime-monitor',
  DOCUMENTS = '/documents',
  REPORTS = '/reports',
  MONITORING = '/monitoring',
  OVERVIEW = '/overview',
  ROLE_SETTINGS = '/settings/roles',
  TEXT_ORDERS = '/text-orders',
}
