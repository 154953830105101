import { gql } from 'graphql-request';

import { TICKET_FILE_FRAGMENT } from './ticketFile';
import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';

export const TICKET_FRAGMENT = gql`
  fragment ticket on Ticket {
    id
    is_imported
    title
    content_html
    created_at
    deadline
    start
    domain {
      ...domainBasic
    }

    type {
      id
      value
      color
    }
    status {
      id
      value
      color
    }

    file {
      ...ticketFile
    }
  }
  ${TICKET_FILE_FRAGMENT}
  ${DOMAIN_BASIC_FRAGMENT}
`;
