import { gql } from 'graphql-request';

import { TEXT_ORDER_IN_THE_LIST_FRAGMENT } from '../fragments';

export const TEXT_ORDER_LIST = gql`
  query textOrders($grid: TextOrderGridInput) {
    textOrders(grid: $grid) {
      total
      rows {
        ...textOrderInTheList
      }
    }
  }
  ${TEXT_ORDER_IN_THE_LIST_FRAGMENT}
`;
