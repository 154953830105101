import { gql } from 'graphql-request';
import { IMPORTED_TICKET_COMMENT_FRAGMENT } from './importedTicketComment';
import { TICKET_FILE_FRAGMENT } from './ticketFile';
import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';

export const IMPORTED_TICKET_FRAGMENT = gql`
  fragment importedTicket on Ticket {
    id
    is_imported
    title
    content_html
    created_at
    updated_at
    deadline
    start

    assignedTickets {
      id
      content_html
      created_at
      comment {
        id
        content_html
        created_at
        file {
          ...ticketCommentFile
        }
      }
    }

    importType {
      id
      value
      color
    }
    importStatus {
      id
      value
      color
    }

    todo {
      id
      status
      text
    }

    comment {
      ...importedTicketComment
    }

    file {
      ...ticketFile
    }

    domain {
      ...domainBasic
    }
  }
  ${IMPORTED_TICKET_COMMENT_FRAGMENT}
  ${DOMAIN_BASIC_FRAGMENT}
  ${TICKET_FILE_FRAGMENT}
`;
