export enum TextOrdersTableHeaderKeys {
  TICKET_NAME = 'ticketName',
  TICKET_TYPE = 'ticketType',
  UPDATED_BY = 'updatedBy',
  ASSIGNED = 'assigned',
  LAST_UPDATE = 'lastUpdate',
  STATUS = 'status',
  PRIORITY = 'priority',
  DEADLINE = 'deadline',
  OWNER = 'owner',
  DOMAIN_NAME = 'domainName',
  CREATED_AT = 'createdAt',
  START = 'start',
}

export enum TextOrdersQuerySortKeys {
  TYPE = 'typeValue',
  UPDATED_AT = 'updatedAt',
  ASSIGNED = 'assignedUserId',
  OWNER = 'ownerUserId',
  STATUS = 'status',
  PRIORITY = 'priorityValue',
  DEADLINE = 'deadline',
  START = 'start',
  TITLE = 'title',
  DOMAIN_NAME = 'domainName',
  CREATED_AT = 'createdAt',
  DATE = 'plannedPublicationDate',
  TEXT_TYPE = 'textType',
  URL = 'url',
  TEXT_URL = 'textUrl',
}

export enum TextOrdersQueryFilterKeys {
  PROJECT_ID = 'projectId',
  TYPE_ID = 'typeId',
  STATUS_ID = 'statusId',
  PRIORITY_ID = 'priorityId',
  ASSIGNED_USER_ID = 'assignedUserId',
  OWNER_USER_ID = 'ownerUserId',
  START = 'start',
  DEADLINE = 'deadline',
  CLOSED = 'closed',
  DOMAIN_ID = 'domainId',
  WATCHED_BY = 'watchedBy',
  IMPORT_STATUS_ID = 'importStatusId',
  IMPORT_TYPE_ID = 'importTypeId',
  STATUS = 'status',
  TEXT_TYPE = 'textType',
  PLANNED_PUBLICATION_DATE = 'plannedPublicationDateRange',
}
