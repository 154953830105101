import { defineMessages } from 'react-intl';

/**
 * i18nCommon Messages
 */
export const i18nMessages = defineMessages({
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  askAQuestion: {
    id: 'askAQuestion',
    defaultMessage: 'Ask a question',
  },
  ticket: {
    id: 'ticket',
    defaultMessage: 'Ticket',
  },
  tickets: {
    id: 'tickets',
    defaultMessage: 'Tickets',
  },
  createTicket: {
    id: 'createTicket',
    defaultMessage: 'Create ticket',
  },
  domain: {
    id: 'domain',
    defaultMessage: 'Domain',
  },
  domains: {
    id: 'domains',
    defaultMessage: 'Domains',
  },
  info: {
    id: 'info',
    defaultMessage: 'Info',
  },
  monitoring: {
    id: 'monitoring',
    defaultMessage: 'Monitoring',
  },
  uptime: {
    id: 'uptime',
    defaultMessage: 'Uptime',
  },
  reports: {
    id: 'reports',
    defaultMessage: 'Reports',
  },
  textOrders: {
    id: 'textOrders',
    defaultMessage: 'Content',
  },
});
