import { useUser as useCurrentUser } from 'context/UserProvider';
import { Permissions, Roles } from 'consts';

export const useIsAuthorized = () => {
  const { role, allowedPermissions = [] } = useCurrentUser();

  const isAuthorized = (requiredRole: Roles) => {
    return role === requiredRole;
  };

  const isAuthorizedToAny = (requiredRoles: Roles[]) => {
    return requiredRoles.includes(role);
  };

  const isAuthorisedToPermission = (requiredPermission: Permissions) => {
    return allowedPermissions.includes(requiredPermission);
  };

  const isAuthorisedToAllPermissions = (requiredPermissions: Permissions[]) => {
    return requiredPermissions.every(perm => allowedPermissions.includes(perm));
  };

  const isAuthorisedToAnyPermissions = (requiredPermissions: Permissions[]) => {
    return requiredPermissions.some(perm => allowedPermissions.includes(perm));
  };

  const isAdmin = isAuthorized(Roles.ADMIN) || isAuthorized(Roles.SUPER_ADMIN);
  const isSuperAdmin = isAuthorized(Roles.SUPER_ADMIN);

  return {
    isAuthorized,
    isAuthorizedToAny,
    isAuthorisedToPermission,
    isAuthorisedToAnyPermissions,
    isAuthorisedToAllPermissions,
    isAdmin,
    isSuperAdmin,
  };
};
