export enum Permissions {
  CREATE_USERS = 'create users',
  UPDATE_USERS = 'update users',
  BLOCK_USERS = 'block users',
  LIST_BLOCKED_USERS = 'list blocked users',
  LIST_PROJECTS = 'list projects',
  MANAGE_PROJECTS = 'manage projects',
  ARCHIVE_PROJECTS = 'archive projects',
  MANAGE_META_TICKETS = 'manage meta tickets',
  LIST_TEAMS = 'list teams',
  UPDATE_TEAMS = 'update teams',
  DELETE_TEAMS = 'delete teams',
  CREATE_TEAMS = 'create teams',
  SHOW_TEAM_STATISTICS = 'show team statistics',
  MANAGE_DOMAINS = 'manage domains',
  MANAGE_DOMAIN_USERS = 'manage domain users',
  EDIT_DOMAINS = 'edit domains',
  MANAGE_ADMINS = 'manage admins',
  RESET_PASSWORD = 'reset password',
  UPDATE_TICKETS = 'update tickets',
  DELETE_TICKETS = 'delete tickets',
  EDIT_TICKET_COMMENTS = 'edit ticket comments',
  DELETE_TICKET_COMMENTS = 'delete ticket comments',
  MANAGE_ROLES = 'manage roles',
  DELETE_PHRASES = 'delete phrases',
  DELETE_MONITORS = 'delete monitors',
  LIST_MONITORS = 'list monitors',
  UPDATE_MONITORS = 'update monitors',
  CREATE_MONITORS = 'create monitors',
  LIST_S4S_INSTANCES = 'list s4s instances',
  CREATE_S4S_INSTANCES = 'create s4s instances',
  UPDATE_S4S_INSTANCES = 'update s4s instances',
  DELETE_S4S_INSTANCES = 'delete s4s instances',
  UPDATE_LINKS = 'update links',
  CREATE_LINKS = 'create links',
  DELETE_LINKS = 'delete links',
  LIST_LINKS = 'list links',
  MANAGE_TRANSLATIONS = 'manage translations',
  RETRIEVE_CREDENTIALS = 'retrieve credentials',
  CREATE_CREDENTIALS = 'create credentials',
  UPDATE_CREDENTIALS = 'update credentials',
  DELETE_CREDENTIALS = 'delete credentials',
  LIST_DOMAINS = 'list domains',
  CREATE_TICKETS = 'create tickets',
  EDIT_TICKET_TIME = 'edit ticket time',
  DELETE_TICKET_TIME = 'delete ticket time',
  SHOW_DOMAINS_FILTER = 'show domains filter',
}
