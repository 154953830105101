import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const ACTIVE_USER_FRAGMENT = gql`
  fragment activeUser on User {
    id
    first_name
    last_name
    email
    phone
    role
    user_type
    navbar_url
    current_account_id
    is_impersonated
    token_expire
    notifications
    domains_notifications
    allowedPermissions
    workerUsers {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
