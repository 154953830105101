import * as Types from './gqlTypes';

import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';
import { fetcher } from 'client';
export const UserBasicFragmentDoc = `
    fragment userBasic on User {
  id
  first_name
  last_name
  blocked
  avatar
}
    `;
export const ActiveUserFragmentDoc = `
    fragment activeUser on User {
  id
  first_name
  last_name
  email
  phone
  role
  user_type
  navbar_url
  current_account_id
  is_impersonated
  token_expire
  notifications
  domains_notifications
  allowedPermissions
  workerUsers {
    ...userBasic
  }
}
    `;
export const ActivityFragmentDoc = `
    fragment activity on Activity {
  id
  event_type
  payload
  created_at
  summary
  target
  user {
    ...userBasic
  }
}
    `;
export const ClientSettingsFragmentDoc = `
    fragment clientSettings on User {
  id
  fe_settings
}
    `;
export const DealFragmentDoc = `
    fragment deal on Deal {
  id
  active
  amount
  currency
  type
  type_name
  orig_real_start_date
}
    `;
export const DomainFragmentDoc = `
    fragment domain on Domain {
  id
  name
  first_deal_start_date
  newestAdsDeal {
    ...deal
  }
  newestFbAdsDeal {
    ...deal
  }
  newestSeoDeal {
    ...deal
  }
  newestSxoDeal {
    ...deal
  }
  newestUxDeal {
    ...deal
  }
}
    `;
export const PartnerUserFragmentDoc = `
    fragment partnerUser on User {
  id
  first_name
  last_name
  contactUser {
    ...userBasic
  }
  avatar
}
    `;
export const UserInTheListFragmentDoc = `
    fragment userInTheList on User {
  id
  first_name
  last_name
  blocked
  email
  phone
  user_type
  notifications
  avatar
  contactUser {
    ...userBasic
  }
  domains_notifications
  supervisor {
    ...userBasic
  }
}
    `;
export const UserFragmentDoc = `
    fragment user on User {
  ...userInTheList
  crm_company_id
  role
  allowedPermissions
}
    `;
export const DealAdminFragmentDoc = `
    fragment dealADMIN on Deal {
  ...deal
  name
  import_id
}
    `;
export const DomainAdminFragmentDoc = `
    fragment domainADMIN on Domain {
  id
  name
  first_deal_start_date
  partnerUsers {
    ...partnerUser
  }
  assignedPartnerUsers {
    ...user
  }
  assignedCsUsers {
    ...user
  }
  newestAdsDeal {
    ...dealADMIN
  }
  newestFbAdsDeal {
    ...dealADMIN
  }
  newestSeoDeal {
    ...dealADMIN
  }
  newestSxoDeal {
    ...dealADMIN
  }
  newestUxDeal {
    ...dealADMIN
  }
}
    `;
export const DealInTheDomainListFragmentDoc = `
    fragment dealInTheDomainList on Deal {
  id
  active
  type
  type_name
  currency
  orig_real_start_date
  amount
}
    `;
export const DomainInTheListFragmentDoc = `
    fragment domainInTheList on Domain {
  id
  name
  updated_at
  first_deal_start_date
  assignedPartnerUsers {
    ...userBasic
  }
  newestGridDeal {
    ...dealInTheDomainList
  }
}
    `;
export const DealInTheDomainListAdminFragmentDoc = `
    fragment dealInTheDomainListADMIN on Deal {
  ...dealInTheDomainList
  name
  import_id
}
    `;
export const DomainInTheListAdminFragmentDoc = `
    fragment domainInTheListADMIN on Domain {
  id
  name
  updated_at
  first_deal_start_date
  partnerUsers {
    ...partnerUser
  }
  assignedPartnerUsers {
    ...userBasic
  }
  assignedCsUsers {
    ...userBasic
  }
  newestGridDeal {
    ...dealInTheDomainListADMIN
  }
}
    `;
export const ExternalReportFragmentDoc = `
    fragment externalReport on ExternalReportDTO {
  id
  domainId
  domainName
  language
  link
  emailSent
  reportDate
}
    `;
export const PermissionFragmentDoc = `
    fragment permission on PermissionsResponse {
  id
  key
  value
}
    `;
export const GroupedPermissionsFragmentDoc = `
    fragment groupedPermissions on GroupedPermissionsResponse {
  id
  value
  options {
    ...permission
  }
}
    `;
export const PhraseFragmentDoc = `
    fragment phrase on Phrase {
  id
  value
  start_position
  user_assigned_url
  location
  location_key
  positionsOrdered {
    date
    position
    url
    positionDifference
  }
  search {
    id
    count
  }
  group {
    id
    name
  }
  domain {
    id
  }
}
    `;
export const GroupedPhrasesFragmentDoc = `
    fragment groupedPhrases on GroupedPhrases {
  id
  name
  phrases {
    ...phrase
  }
}
    `;
export const TicketCommentFileFragmentDoc = `
    fragment ticketCommentFile on TicketCommentFile {
  id
  title
  sizeInKb
}
    `;
export const ImportedTicketCommentFragmentDoc = `
    fragment importedTicketComment on TicketComment {
  id
  content_html
  created_at
  file {
    ...ticketCommentFile
  }
}
    `;
export const TicketFileFragmentDoc = `
    fragment ticketFile on TicketFile {
  id
  title
  sizeInKb
}
    `;
export const DomainBasicFragmentDoc = `
    fragment domainBasic on Domain {
  id
  name
}
    `;
export const ImportedTicketFragmentDoc = `
    fragment importedTicket on Ticket {
  id
  is_imported
  title
  content_html
  created_at
  updated_at
  deadline
  start
  assignedTickets {
    id
    content_html
    created_at
    comment {
      id
      content_html
      created_at
      file {
        ...ticketCommentFile
      }
    }
  }
  importType {
    id
    value
    color
  }
  importStatus {
    id
    value
    color
  }
  todo {
    id
    status
    text
  }
  comment {
    ...importedTicketComment
  }
  file {
    ...ticketFile
  }
  domain {
    ...domainBasic
  }
}
    `;
export const ImportedTicketInTheListFragmentDoc = `
    fragment importedTicketInTheList on Ticket {
  id
  is_imported
  title
  start
  deadline
  updated_at
  created_at
  importType {
    id
    value
    color
  }
  importStatus {
    id
    value
    color
  }
}
    `;
export const MonitorFragmentDoc = `
    fragment monitor on Monitor {
  id
  monitor_id
  friendly_name
  url
  status
  daily_uptime_ratio
  weekly_uptime_ratio
  monthly_uptime_ratio
  domain {
    id
  }
}
    `;
export const NotificationFragmentDoc = `
    fragment notification on Notification {
  id
  type
  data
  created_at
  read_at
  owner {
    ...userBasic
  }
}
    `;
export const PhraseGroupFragmentDoc = `
    fragment phraseGroup on PhraseGroup {
  id
  name
  domain_id
}
    `;
export const RoleFragmentDoc = `
    fragment role on Role {
  id
  name
  title
  permissions {
    id
    name
  }
}
    `;
export const RoleInTheListFragmentDoc = `
    fragment roleInTheList on RoleResponse {
  id
  name
  title
  permissions
}
    `;
export const S4sInstanceFragmentDoc = `
    fragment s4sInstance on S4sInstance {
  id
  name
  host
  port
  prefix
  db
  phrases
  domains {
    id
    name
  }
}
    `;
export const TeamFragmentDoc = `
    fragment team on Team {
  totalMembers
  id
  name
  leader {
    ...userInTheList
  }
  members {
    ...userInTheList
  }
}
    `;
export const TeamBasicFragmentDoc = `
    fragment teamBasic on Team {
  id
  name
  leader {
    id
  }
  members {
    id
  }
}
    `;
export const TeamInTheListFragmentDoc = `
    fragment teamInTheList on Team {
  totalMembers
  id
  name
  leader {
    ...userBasic
  }
}
    `;
export const TeamStatisticsTotalsFragmentDoc = `
    fragment teamStatisticsTotals on Totals {
  thisWeek
  overdue
  today
  open
  closed
  domains
}
    `;
export const TextOrderInTheListFragmentDoc = `
    fragment textOrderInTheList on TextOrder {
  id
  title
  updated_at
  created_at
  status
  text_type
  order_type
  url
  text_url
  planned_publication_date
  domain {
    id
    name
  }
}
    `;
export const TicketFragmentDoc = `
    fragment ticket on Ticket {
  id
  is_imported
  title
  content_html
  created_at
  deadline
  start
  domain {
    ...domainBasic
  }
  type {
    id
    value
    color
  }
  status {
    id
    value
    color
  }
  file {
    ...ticketFile
  }
}
    `;
export const TicketBasicFragmentDoc = `
    fragment ticketBasic on Ticket {
  sequence
  id
  title
  content
  created_at
  updated_at
  deadline
  start
  owner {
    ...userBasic
  }
  assigned {
    ...userBasic
  }
  updatedBy {
    ...userBasic
  }
}
    `;
export const TicketCommentFragmentDoc = `
    fragment ticketComment on TicketComment {
  id
  content_html
  created_at
  user {
    ...userBasic
  }
  file {
    ...ticketCommentFile
  }
}
    `;
export const TicketInTheListFragmentDoc = `
    fragment ticketInTheList on Ticket {
  id
  is_imported
  title
  start
  deadline
  updated_at
  created_at
  domain {
    ...domainBasic
  }
  type {
    id
    value
    color
  }
  status {
    id
    value
    color
  }
  importType {
    id
    value
    color
  }
  importStatus {
    id
    value
    color
  }
}
    `;
export const StatusFragmentDoc = `
    fragment status on Status {
  id
  value
  treat_as_closed
  color
  default
  archived
}
    `;
export const TicketTodoFragmentDoc = `
    fragment ticketTodo on TicketTodo {
  id
  text
  status
  priority
}
    `;
export const TypeFragmentDoc = `
    fragment type on Type {
  id
  value
  color
  default
  archived
}
    `;
export const TicketUsersFragmentDoc = `
    fragment ticketUsers on Ticket {
  id
  owner {
    ...userBasic
  }
  assigned {
    ...userBasic
  }
  watchers {
    ...userBasic
  }
}
    `;
export const UserStatisticsDetailsFragmentDoc = `
    fragment userStatisticsDetails on Details {
  id
  thisWeek
  overdue
  today
  open
  closed
  domains
}
    `;
export const AddTicketStatusDocument = `
    mutation addTicketStatus($value: String!, $color: ColorPaletteEnum!, $treatAsClosed: Boolean) {
  addTicketStatus(value: $value, color: $color, treatAsClosed: $treatAsClosed) {
    ...status
  }
}
    ${StatusFragmentDoc}`;
export const useAddTicketStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.AddTicketStatusMutation,
    TError,
    Types.AddTicketStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.AddTicketStatusMutation,
    TError,
    Types.AddTicketStatusMutationVariables,
    TContext
  >(
    ['addTicketStatus'],
    (variables?: Types.AddTicketStatusMutationVariables) =>
      fetcher<Types.AddTicketStatusMutation, Types.AddTicketStatusMutationVariables>(
        AddTicketStatusDocument,
        variables,
      )(),
    options,
  );
export const AddTicketTypeDocument = `
    mutation addTicketType($value: String!, $color: ColorPaletteEnum!) {
  addTicketType(value: $value, color: $color) {
    ...type
  }
}
    ${TypeFragmentDoc}`;
export const useAddTicketTypeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.AddTicketTypeMutation,
    TError,
    Types.AddTicketTypeMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.AddTicketTypeMutation, TError, Types.AddTicketTypeMutationVariables, TContext>(
    ['addTicketType'],
    (variables?: Types.AddTicketTypeMutationVariables) =>
      fetcher<Types.AddTicketTypeMutation, Types.AddTicketTypeMutationVariables>(
        AddTicketTypeDocument,
        variables,
      )(),
    options,
  );
export const AddTodoDocument = `
    mutation addTodo($ticketId: ID!, $text: String!, $priority: Float) {
  addTodo(ticketId: $ticketId, text: $text, priority: $priority) {
    ...ticketTodo
  }
}
    ${TicketTodoFragmentDoc}`;
export const useAddTodoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.AddTodoMutation,
    TError,
    Types.AddTodoMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.AddTodoMutation, TError, Types.AddTodoMutationVariables, TContext>(
    ['addTodo'],
    (variables?: Types.AddTodoMutationVariables) =>
      fetcher<Types.AddTodoMutation, Types.AddTodoMutationVariables>(AddTodoDocument, variables)(),
    options,
  );
export const AddTodosDocument = `
    mutation addTodos($ticketId: ID!, $todos: [TodoInput!]!) {
  addTodos(ticketId: $ticketId, todos: $todos) {
    ...ticketTodo
  }
}
    ${TicketTodoFragmentDoc}`;
export const useAddTodosMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.AddTodosMutation,
    TError,
    Types.AddTodosMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.AddTodosMutation, TError, Types.AddTodosMutationVariables, TContext>(
    ['addTodos'],
    (variables?: Types.AddTodosMutationVariables) =>
      fetcher<Types.AddTodosMutation, Types.AddTodosMutationVariables>(
        AddTodosDocument,
        variables,
      )(),
    options,
  );
export const BlockUserDocument = `
    mutation blockUser($id: ID!) {
  blockUser(id: $id) {
    id
  }
}
    `;
export const useBlockUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.BlockUserMutation,
    TError,
    Types.BlockUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.BlockUserMutation, TError, Types.BlockUserMutationVariables, TContext>(
    ['blockUser'],
    (variables?: Types.BlockUserMutationVariables) =>
      fetcher<Types.BlockUserMutation, Types.BlockUserMutationVariables>(
        BlockUserDocument,
        variables,
      )(),
    options,
  );
export const ChangePasswordDocument = `
    mutation changePassword($newPassword: String!, $oldPassword: String!) {
  changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
    ...user
  }
}
    ${UserFragmentDoc}
${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useChangePasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.ChangePasswordMutation,
    TError,
    Types.ChangePasswordMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.ChangePasswordMutation,
    TError,
    Types.ChangePasswordMutationVariables,
    TContext
  >(
    ['changePassword'],
    (variables?: Types.ChangePasswordMutationVariables) =>
      fetcher<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        variables,
      )(),
    options,
  );
export const ChangeTicketAssigneeDocument = `
    mutation changeTicketAssignee($ticketId: ID!, $assignedUserId: ID!) {
  changeTicketAssignee(ticketId: $ticketId, assignedUserId: $assignedUserId) {
    ...ticketUsers
  }
}
    ${TicketUsersFragmentDoc}
${UserBasicFragmentDoc}`;
export const useChangeTicketAssigneeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.ChangeTicketAssigneeMutation,
    TError,
    Types.ChangeTicketAssigneeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.ChangeTicketAssigneeMutation,
    TError,
    Types.ChangeTicketAssigneeMutationVariables,
    TContext
  >(
    ['changeTicketAssignee'],
    (variables?: Types.ChangeTicketAssigneeMutationVariables) =>
      fetcher<Types.ChangeTicketAssigneeMutation, Types.ChangeTicketAssigneeMutationVariables>(
        ChangeTicketAssigneeDocument,
        variables,
      )(),
    options,
  );
export const CreateMonitorDocument = `
    mutation createMonitor($domainId: String!, $url: String!) {
  createMonitor(domainId: $domainId, url: $url) {
    ...monitor
  }
}
    ${MonitorFragmentDoc}`;
export const useCreateMonitorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateMonitorMutation,
    TError,
    Types.CreateMonitorMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.CreateMonitorMutation, TError, Types.CreateMonitorMutationVariables, TContext>(
    ['createMonitor'],
    (variables?: Types.CreateMonitorMutationVariables) =>
      fetcher<Types.CreateMonitorMutation, Types.CreateMonitorMutationVariables>(
        CreateMonitorDocument,
        variables,
      )(),
    options,
  );
export const CreatePhraseGroupDocument = `
    mutation createPhraseGroup($name: String!, $domainId: String!, $phraseIds: [String!]) {
  createPhraseGroup(name: $name, domainId: $domainId, phraseIds: $phraseIds) {
    ...phraseGroup
  }
}
    ${PhraseGroupFragmentDoc}`;
export const useCreatePhraseGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreatePhraseGroupMutation,
    TError,
    Types.CreatePhraseGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.CreatePhraseGroupMutation,
    TError,
    Types.CreatePhraseGroupMutationVariables,
    TContext
  >(
    ['createPhraseGroup'],
    (variables?: Types.CreatePhraseGroupMutationVariables) =>
      fetcher<Types.CreatePhraseGroupMutation, Types.CreatePhraseGroupMutationVariables>(
        CreatePhraseGroupDocument,
        variables,
      )(),
    options,
  );
export const CreatePhrasesDocument = `
    mutation createPhrases($phrases: [PhraseDTOInput!]!, $domainId: String!, $groupId: String) {
  createPhrases(phrases: $phrases, domainId: $domainId, groupId: $groupId) {
    ...phrase
  }
}
    ${PhraseFragmentDoc}`;
export const useCreatePhrasesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreatePhrasesMutation,
    TError,
    Types.CreatePhrasesMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.CreatePhrasesMutation, TError, Types.CreatePhrasesMutationVariables, TContext>(
    ['createPhrases'],
    (variables?: Types.CreatePhrasesMutationVariables) =>
      fetcher<Types.CreatePhrasesMutation, Types.CreatePhrasesMutationVariables>(
        CreatePhrasesDocument,
        variables,
      )(),
    options,
  );
export const CreateRoleDocument = `
    mutation createRole($title: String!, $permissions: [PermissionEnum!]) {
  createRole(title: $title, permissions: $permissions) {
    ...role
  }
}
    ${RoleFragmentDoc}`;
export const useCreateRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateRoleMutation,
    TError,
    Types.CreateRoleMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.CreateRoleMutation, TError, Types.CreateRoleMutationVariables, TContext>(
    ['createRole'],
    (variables?: Types.CreateRoleMutationVariables) =>
      fetcher<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>(
        CreateRoleDocument,
        variables,
      )(),
    options,
  );
export const CreateS4sInstanceDocument = `
    mutation createS4sInstance($name: String!, $host: String!, $port: Int!, $user: String!, $password: String!, $db: String!, $prefix: String!) {
  createS4sInstance(
    name: $name
    host: $host
    port: $port
    user: $user
    password: $password
    db: $db
    prefix: $prefix
  ) {
    ...s4sInstance
  }
}
    ${S4sInstanceFragmentDoc}`;
export const useCreateS4sInstanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateS4sInstanceMutation,
    TError,
    Types.CreateS4sInstanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.CreateS4sInstanceMutation,
    TError,
    Types.CreateS4sInstanceMutationVariables,
    TContext
  >(
    ['createS4sInstance'],
    (variables?: Types.CreateS4sInstanceMutationVariables) =>
      fetcher<Types.CreateS4sInstanceMutation, Types.CreateS4sInstanceMutationVariables>(
        CreateS4sInstanceDocument,
        variables,
      )(),
    options,
  );
export const CreateTicketDocument = `
    mutation createTicket($title: String!, $contentHtml: String, $typeId: ID, $deadline: DateTime, $start: DateTime, $assignedToTicketId: ID, $domainId: ID) {
  createTicket(
    title: $title
    contentHtml: $contentHtml
    typeId: $typeId
    deadline: $deadline
    start: $start
    assignedToTicketId: $assignedToTicketId
    domainId: $domainId
  ) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useCreateTicketMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateTicketMutation,
    TError,
    Types.CreateTicketMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.CreateTicketMutation, TError, Types.CreateTicketMutationVariables, TContext>(
    ['createTicket'],
    (variables?: Types.CreateTicketMutationVariables) =>
      fetcher<Types.CreateTicketMutation, Types.CreateTicketMutationVariables>(
        CreateTicketDocument,
        variables,
      )(),
    options,
  );
export const CreateTicketCommentDocument = `
    mutation createTicketComment($ticketId: ID!, $contentHtml: String!) {
  createTicketComment(ticketId: $ticketId, contentHtml: $contentHtml) {
    ...ticketComment
  }
}
    ${TicketCommentFragmentDoc}
${UserBasicFragmentDoc}
${TicketCommentFileFragmentDoc}`;
export const useCreateTicketCommentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateTicketCommentMutation,
    TError,
    Types.CreateTicketCommentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.CreateTicketCommentMutation,
    TError,
    Types.CreateTicketCommentMutationVariables,
    TContext
  >(
    ['createTicketComment'],
    (variables?: Types.CreateTicketCommentMutationVariables) =>
      fetcher<Types.CreateTicketCommentMutation, Types.CreateTicketCommentMutationVariables>(
        CreateTicketCommentDocument,
        variables,
      )(),
    options,
  );
export const CreateUserDocument = `
    mutation createUser($email: String!, $firstName: String!, $lastName: String!, $phone: String, $role: String, $crmCompanyId: Int, $contactUserId: ID, $userType: UserTypeEnum, $supervisorId: ID) {
  createUser(
    email: $email
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    role: $role
    crmCompanyId: $crmCompanyId
    contactUserId: $contactUserId
    userType: $userType
    supervisorId: $supervisorId
  ) {
    ...user
  }
}
    ${UserFragmentDoc}
${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.CreateUserMutation,
    TError,
    Types.CreateUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.CreateUserMutation, TError, Types.CreateUserMutationVariables, TContext>(
    ['createUser'],
    (variables?: Types.CreateUserMutationVariables) =>
      fetcher<Types.CreateUserMutation, Types.CreateUserMutationVariables>(
        CreateUserDocument,
        variables,
      )(),
    options,
  );
export const DeleteMonitorDocument = `
    mutation deleteMonitor($id: String!) {
  deleteMonitor(id: $id)
}
    `;
export const useDeleteMonitorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteMonitorMutation,
    TError,
    Types.DeleteMonitorMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.DeleteMonitorMutation, TError, Types.DeleteMonitorMutationVariables, TContext>(
    ['deleteMonitor'],
    (variables?: Types.DeleteMonitorMutationVariables) =>
      fetcher<Types.DeleteMonitorMutation, Types.DeleteMonitorMutationVariables>(
        DeleteMonitorDocument,
        variables,
      )(),
    options,
  );
export const DeletePhraseGroupDocument = `
    mutation deletePhraseGroup($groupId: String!) {
  deletePhraseGroup(groupId: $groupId) {
    id
  }
}
    `;
export const useDeletePhraseGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeletePhraseGroupMutation,
    TError,
    Types.DeletePhraseGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeletePhraseGroupMutation,
    TError,
    Types.DeletePhraseGroupMutationVariables,
    TContext
  >(
    ['deletePhraseGroup'],
    (variables?: Types.DeletePhraseGroupMutationVariables) =>
      fetcher<Types.DeletePhraseGroupMutation, Types.DeletePhraseGroupMutationVariables>(
        DeletePhraseGroupDocument,
        variables,
      )(),
    options,
  );
export const DeletePhrasesDocument = `
    mutation deletePhrases($ids: [String!]!) {
  deletePhrases(ids: $ids) {
    id
  }
}
    `;
export const useDeletePhrasesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeletePhrasesMutation,
    TError,
    Types.DeletePhrasesMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.DeletePhrasesMutation, TError, Types.DeletePhrasesMutationVariables, TContext>(
    ['deletePhrases'],
    (variables?: Types.DeletePhrasesMutationVariables) =>
      fetcher<Types.DeletePhrasesMutation, Types.DeletePhrasesMutationVariables>(
        DeletePhrasesDocument,
        variables,
      )(),
    options,
  );
export const DeleteRoleDocument = `
    mutation deleteRole($roleId: String!) {
  deleteRole(roleId: $roleId) {
    id
  }
}
    `;
export const useDeleteRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteRoleMutation,
    TError,
    Types.DeleteRoleMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.DeleteRoleMutation, TError, Types.DeleteRoleMutationVariables, TContext>(
    ['deleteRole'],
    (variables?: Types.DeleteRoleMutationVariables) =>
      fetcher<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>(
        DeleteRoleDocument,
        variables,
      )(),
    options,
  );
export const DeleteS4sInstanceDocument = `
    mutation deleteS4sInstance($id: ID!) {
  deleteS4sInstance(id: $id) {
    id
  }
}
    `;
export const useDeleteS4sInstanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteS4sInstanceMutation,
    TError,
    Types.DeleteS4sInstanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteS4sInstanceMutation,
    TError,
    Types.DeleteS4sInstanceMutationVariables,
    TContext
  >(
    ['deleteS4sInstance'],
    (variables?: Types.DeleteS4sInstanceMutationVariables) =>
      fetcher<Types.DeleteS4sInstanceMutation, Types.DeleteS4sInstanceMutationVariables>(
        DeleteS4sInstanceDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketDocument = `
    mutation deleteTicket($ticketId: ID!) {
  deleteTicket(ticketId: $ticketId) {
    id
  }
}
    `;
export const useDeleteTicketMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketMutation,
    TError,
    Types.DeleteTicketMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.DeleteTicketMutation, TError, Types.DeleteTicketMutationVariables, TContext>(
    ['deleteTicket'],
    (variables?: Types.DeleteTicketMutationVariables) =>
      fetcher<Types.DeleteTicketMutation, Types.DeleteTicketMutationVariables>(
        DeleteTicketDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketAttachmentDocument = `
    mutation deleteTicketAttachment($id: ID!) {
  deleteTicketAttachment(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketAttachmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketAttachmentMutation,
    TError,
    Types.DeleteTicketAttachmentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketAttachmentMutation,
    TError,
    Types.DeleteTicketAttachmentMutationVariables,
    TContext
  >(
    ['deleteTicketAttachment'],
    (variables?: Types.DeleteTicketAttachmentMutationVariables) =>
      fetcher<Types.DeleteTicketAttachmentMutation, Types.DeleteTicketAttachmentMutationVariables>(
        DeleteTicketAttachmentDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketCommentDocument = `
    mutation deleteTicketComment($id: ID!) {
  deleteTicketComment(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketCommentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketCommentMutation,
    TError,
    Types.DeleteTicketCommentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketCommentMutation,
    TError,
    Types.DeleteTicketCommentMutationVariables,
    TContext
  >(
    ['deleteTicketComment'],
    (variables?: Types.DeleteTicketCommentMutationVariables) =>
      fetcher<Types.DeleteTicketCommentMutation, Types.DeleteTicketCommentMutationVariables>(
        DeleteTicketCommentDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketCommentAttachmentDocument = `
    mutation deleteTicketCommentAttachment($id: ID!) {
  deleteTicketCommentAttachment(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketCommentAttachmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketCommentAttachmentMutation,
    TError,
    Types.DeleteTicketCommentAttachmentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketCommentAttachmentMutation,
    TError,
    Types.DeleteTicketCommentAttachmentMutationVariables,
    TContext
  >(
    ['deleteTicketCommentAttachment'],
    (variables?: Types.DeleteTicketCommentAttachmentMutationVariables) =>
      fetcher<
        Types.DeleteTicketCommentAttachmentMutation,
        Types.DeleteTicketCommentAttachmentMutationVariables
      >(DeleteTicketCommentAttachmentDocument, variables)(),
    options,
  );
export const DeleteTicketPriorityDocument = `
    mutation deleteTicketPriority($id: ID!) {
  deleteTicketPriority(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketPriorityMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketPriorityMutation,
    TError,
    Types.DeleteTicketPriorityMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketPriorityMutation,
    TError,
    Types.DeleteTicketPriorityMutationVariables,
    TContext
  >(
    ['deleteTicketPriority'],
    (variables?: Types.DeleteTicketPriorityMutationVariables) =>
      fetcher<Types.DeleteTicketPriorityMutation, Types.DeleteTicketPriorityMutationVariables>(
        DeleteTicketPriorityDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketStatusDocument = `
    mutation deleteTicketStatus($id: ID!) {
  deleteTicketStatus(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketStatusMutation,
    TError,
    Types.DeleteTicketStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketStatusMutation,
    TError,
    Types.DeleteTicketStatusMutationVariables,
    TContext
  >(
    ['deleteTicketStatus'],
    (variables?: Types.DeleteTicketStatusMutationVariables) =>
      fetcher<Types.DeleteTicketStatusMutation, Types.DeleteTicketStatusMutationVariables>(
        DeleteTicketStatusDocument,
        variables,
      )(),
    options,
  );
export const DeleteTicketTypeDocument = `
    mutation deleteTicketType($id: ID!) {
  deleteTicketType(id: $id) {
    id
  }
}
    `;
export const useDeleteTicketTypeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTicketTypeMutation,
    TError,
    Types.DeleteTicketTypeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.DeleteTicketTypeMutation,
    TError,
    Types.DeleteTicketTypeMutationVariables,
    TContext
  >(
    ['deleteTicketType'],
    (variables?: Types.DeleteTicketTypeMutationVariables) =>
      fetcher<Types.DeleteTicketTypeMutation, Types.DeleteTicketTypeMutationVariables>(
        DeleteTicketTypeDocument,
        variables,
      )(),
    options,
  );
export const DeleteTodoDocument = `
    mutation deleteTodo($id: ID!) {
  deleteTodo(id: $id) {
    id
  }
}
    `;
export const useDeleteTodoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.DeleteTodoMutation,
    TError,
    Types.DeleteTodoMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.DeleteTodoMutation, TError, Types.DeleteTodoMutationVariables, TContext>(
    ['deleteTodo'],
    (variables?: Types.DeleteTodoMutationVariables) =>
      fetcher<Types.DeleteTodoMutation, Types.DeleteTodoMutationVariables>(
        DeleteTodoDocument,
        variables,
      )(),
    options,
  );
export const GroupPhrasesDocument = `
    mutation groupPhrases($groupId: String!, $ids: [String!]!) {
  groupPhrases(groupId: $groupId, ids: $ids) {
    ...phraseGroup
  }
}
    ${PhraseGroupFragmentDoc}`;
export const useGroupPhrasesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.GroupPhrasesMutation,
    TError,
    Types.GroupPhrasesMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.GroupPhrasesMutation, TError, Types.GroupPhrasesMutationVariables, TContext>(
    ['groupPhrases'],
    (variables?: Types.GroupPhrasesMutationVariables) =>
      fetcher<Types.GroupPhrasesMutation, Types.GroupPhrasesMutationVariables>(
        GroupPhrasesDocument,
        variables,
      )(),
    options,
  );
export const ImpersonateDocument = `
    mutation impersonate($userId: ID!, $accountId: String) {
  impersonate(userId: $userId, accountId: $accountId) {
    email
    first_name
    last_name
  }
}
    `;
export const useImpersonateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.ImpersonateMutation,
    TError,
    Types.ImpersonateMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.ImpersonateMutation, TError, Types.ImpersonateMutationVariables, TContext>(
    ['impersonate'],
    (variables?: Types.ImpersonateMutationVariables) =>
      fetcher<Types.ImpersonateMutation, Types.ImpersonateMutationVariables>(
        ImpersonateDocument,
        variables,
      )(),
    options,
  );
export const LeaveImpersonationDocument = `
    mutation leaveImpersonation {
  leaveImpersonation {
    id
  }
}
    `;
export const useLeaveImpersonationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.LeaveImpersonationMutation,
    TError,
    Types.LeaveImpersonationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.LeaveImpersonationMutation,
    TError,
    Types.LeaveImpersonationMutationVariables,
    TContext
  >(
    ['leaveImpersonation'],
    (variables?: Types.LeaveImpersonationMutationVariables) =>
      fetcher<Types.LeaveImpersonationMutation, Types.LeaveImpersonationMutationVariables>(
        LeaveImpersonationDocument,
        variables,
      )(),
    options,
  );
export const LoginUserDocument = `
    mutation loginUser($email: String!, $password: String!) {
  loginUser(email: $email, password: $password) {
    email
    first_name
    last_name
  }
}
    `;
export const useLoginUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.LoginUserMutation,
    TError,
    Types.LoginUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.LoginUserMutation, TError, Types.LoginUserMutationVariables, TContext>(
    ['loginUser'],
    (variables?: Types.LoginUserMutationVariables) =>
      fetcher<Types.LoginUserMutation, Types.LoginUserMutationVariables>(
        LoginUserDocument,
        variables,
      )(),
    options,
  );
export const LogoutUserDocument = `
    mutation logoutUser {
  logoutUser
}
    `;
export const useLogoutUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.LogoutUserMutation,
    TError,
    Types.LogoutUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.LogoutUserMutation, TError, Types.LogoutUserMutationVariables, TContext>(
    ['logoutUser'],
    (variables?: Types.LogoutUserMutationVariables) =>
      fetcher<Types.LogoutUserMutation, Types.LogoutUserMutationVariables>(
        LogoutUserDocument,
        variables,
      )(),
    options,
  );
export const MarkAllNotificationReadDocument = `
    mutation markAllNotificationRead {
  markAllNotificationRead
}
    `;
export const useMarkAllNotificationReadMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.MarkAllNotificationReadMutation,
    TError,
    Types.MarkAllNotificationReadMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.MarkAllNotificationReadMutation,
    TError,
    Types.MarkAllNotificationReadMutationVariables,
    TContext
  >(
    ['markAllNotificationRead'],
    (variables?: Types.MarkAllNotificationReadMutationVariables) =>
      fetcher<
        Types.MarkAllNotificationReadMutation,
        Types.MarkAllNotificationReadMutationVariables
      >(MarkAllNotificationReadDocument, variables)(),
    options,
  );
export const MarkReadNotificationDocument = `
    mutation markReadNotification($id: ID!) {
  markReadNotification(id: $id)
}
    `;
export const useMarkReadNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.MarkReadNotificationMutation,
    TError,
    Types.MarkReadNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.MarkReadNotificationMutation,
    TError,
    Types.MarkReadNotificationMutationVariables,
    TContext
  >(
    ['markReadNotification'],
    (variables?: Types.MarkReadNotificationMutationVariables) =>
      fetcher<Types.MarkReadNotificationMutation, Types.MarkReadNotificationMutationVariables>(
        MarkReadNotificationDocument,
        variables,
      )(),
    options,
  );
export const MarkUnreadNotificationDocument = `
    mutation markUnreadNotification($id: ID!) {
  markUnreadNotification(id: $id)
}
    `;
export const useMarkUnreadNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.MarkUnreadNotificationMutation,
    TError,
    Types.MarkUnreadNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.MarkUnreadNotificationMutation,
    TError,
    Types.MarkUnreadNotificationMutationVariables,
    TContext
  >(
    ['markUnreadNotification'],
    (variables?: Types.MarkUnreadNotificationMutationVariables) =>
      fetcher<Types.MarkUnreadNotificationMutation, Types.MarkUnreadNotificationMutationVariables>(
        MarkUnreadNotificationDocument,
        variables,
      )(),
    options,
  );
export const ResetPasswordDocument = `
    mutation resetPassword($token: String!, $password: String!, $email: String!) {
  resetPassword(token: $token, password: $password, email: $email)
}
    `;
export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.ResetPasswordMutation,
    TError,
    Types.ResetPasswordMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.ResetPasswordMutation, TError, Types.ResetPasswordMutationVariables, TContext>(
    ['resetPassword'],
    (variables?: Types.ResetPasswordMutationVariables) =>
      fetcher<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        variables,
      )(),
    options,
  );
export const SendResetPasswordDocument = `
    mutation sendResetPassword($email: String!) {
  sendResetPassword(email: $email)
}
    `;
export const useSendResetPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SendResetPasswordMutation,
    TError,
    Types.SendResetPasswordMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.SendResetPasswordMutation,
    TError,
    Types.SendResetPasswordMutationVariables,
    TContext
  >(
    ['sendResetPassword'],
    (variables?: Types.SendResetPasswordMutationVariables) =>
      fetcher<Types.SendResetPasswordMutation, Types.SendResetPasswordMutationVariables>(
        SendResetPasswordDocument,
        variables,
      )(),
    options,
  );
export const SetTicketPriorityDocument = `
    mutation setTicketPriority($id: ID!, $priorityValue: Float!) {
  setTicketPriority(id: $id, priorityValue: $priorityValue) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useSetTicketPriorityMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SetTicketPriorityMutation,
    TError,
    Types.SetTicketPriorityMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.SetTicketPriorityMutation,
    TError,
    Types.SetTicketPriorityMutationVariables,
    TContext
  >(
    ['setTicketPriority'],
    (variables?: Types.SetTicketPriorityMutationVariables) =>
      fetcher<Types.SetTicketPriorityMutation, Types.SetTicketPriorityMutationVariables>(
        SetTicketPriorityDocument,
        variables,
      )(),
    options,
  );
export const SetTranslationsDocument = `
    mutation setTranslations($translations: [GroupedTranslatablesInput!]!) {
  setTranslations(translations: $translations)
}
    `;
export const useSetTranslationsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SetTranslationsMutation,
    TError,
    Types.SetTranslationsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.SetTranslationsMutation,
    TError,
    Types.SetTranslationsMutationVariables,
    TContext
  >(
    ['setTranslations'],
    (variables?: Types.SetTranslationsMutationVariables) =>
      fetcher<Types.SetTranslationsMutation, Types.SetTranslationsMutationVariables>(
        SetTranslationsDocument,
        variables,
      )(),
    options,
  );
export const SyncRolePermissionsDocument = `
    mutation syncRolePermissions($roleId: String!, $permissions: [PermissionEnum!]!) {
  syncRolePermissions(roleId: $roleId, permissions: $permissions) {
    ...role
  }
}
    ${RoleFragmentDoc}`;
export const useSyncRolePermissionsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SyncRolePermissionsMutation,
    TError,
    Types.SyncRolePermissionsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.SyncRolePermissionsMutation,
    TError,
    Types.SyncRolePermissionsMutationVariables,
    TContext
  >(
    ['syncRolePermissions'],
    (variables?: Types.SyncRolePermissionsMutationVariables) =>
      fetcher<Types.SyncRolePermissionsMutation, Types.SyncRolePermissionsMutationVariables>(
        SyncRolePermissionsDocument,
        variables,
      )(),
    options,
  );
export const SyncUserPermissionsDocument = `
    mutation syncUserPermissions($userId: String!, $role: String!, $directPermissions: [PermissionEnum!]!) {
  syncUserPermissions(
    userId: $userId
    role: $role
    directPermissions: $directPermissions
  ) {
    permissions
    user {
      id
    }
    role
  }
}
    `;
export const useSyncUserPermissionsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.SyncUserPermissionsMutation,
    TError,
    Types.SyncUserPermissionsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.SyncUserPermissionsMutation,
    TError,
    Types.SyncUserPermissionsMutationVariables,
    TContext
  >(
    ['syncUserPermissions'],
    (variables?: Types.SyncUserPermissionsMutationVariables) =>
      fetcher<Types.SyncUserPermissionsMutation, Types.SyncUserPermissionsMutationVariables>(
        SyncUserPermissionsDocument,
        variables,
      )(),
    options,
  );
export const UnblockUserDocument = `
    mutation unblockUser($id: ID!) {
  unblockUser(id: $id) {
    id
  }
}
    `;
export const useUnblockUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UnblockUserMutation,
    TError,
    Types.UnblockUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UnblockUserMutation, TError, Types.UnblockUserMutationVariables, TContext>(
    ['unblockUser'],
    (variables?: Types.UnblockUserMutationVariables) =>
      fetcher<Types.UnblockUserMutation, Types.UnblockUserMutationVariables>(
        UnblockUserDocument,
        variables,
      )(),
    options,
  );
export const UngroupPhrasesDocument = `
    mutation ungroupPhrases($ids: [String!]!) {
  ungroupPhrases(ids: $ids) {
    id
  }
}
    `;
export const useUngroupPhrasesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UngroupPhrasesMutation,
    TError,
    Types.UngroupPhrasesMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UngroupPhrasesMutation,
    TError,
    Types.UngroupPhrasesMutationVariables,
    TContext
  >(
    ['ungroupPhrases'],
    (variables?: Types.UngroupPhrasesMutationVariables) =>
      fetcher<Types.UngroupPhrasesMutation, Types.UngroupPhrasesMutationVariables>(
        UngroupPhrasesDocument,
        variables,
      )(),
    options,
  );
export const UnwatchTicketDocument = `
    mutation unwatchTicket($ticketId: ID!, $userIds: [ID!]) {
  unwatchTicket(ticketId: $ticketId, userIds: $userIds) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useUnwatchTicketMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UnwatchTicketMutation,
    TError,
    Types.UnwatchTicketMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UnwatchTicketMutation, TError, Types.UnwatchTicketMutationVariables, TContext>(
    ['unwatchTicket'],
    (variables?: Types.UnwatchTicketMutationVariables) =>
      fetcher<Types.UnwatchTicketMutation, Types.UnwatchTicketMutationVariables>(
        UnwatchTicketDocument,
        variables,
      )(),
    options,
  );
export const UpdateClientSettingsDocument = `
    mutation updateClientSettings($id: ID!, $userInput: UpdateUserInput!) {
  updateUser(id: $id, userInput: $userInput) {
    ...clientSettings
  }
}
    ${ClientSettingsFragmentDoc}`;
export const useUpdateClientSettingsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateClientSettingsMutation,
    TError,
    Types.UpdateClientSettingsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateClientSettingsMutation,
    TError,
    Types.UpdateClientSettingsMutationVariables,
    TContext
  >(
    ['updateClientSettings'],
    (variables?: Types.UpdateClientSettingsMutationVariables) =>
      fetcher<Types.UpdateClientSettingsMutation, Types.UpdateClientSettingsMutationVariables>(
        UpdateClientSettingsDocument,
        variables,
      )(),
    options,
  );
export const UpdateDomainInfoDocument = `
    mutation updateDomainInfo($id: ID!, $name: String, $assignedPartnerUserIds: [ID!], $assignedCsUserIds: [ID!]) {
  updateDomain(
    id: $id
    name: $name
    assignedPartnerUserIds: $assignedPartnerUserIds
    assignedCsUserIds: $assignedCsUserIds
  ) {
    ...domain
  }
}
    ${DomainFragmentDoc}
${DealFragmentDoc}`;
export const useUpdateDomainInfoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateDomainInfoMutation,
    TError,
    Types.UpdateDomainInfoMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateDomainInfoMutation,
    TError,
    Types.UpdateDomainInfoMutationVariables,
    TContext
  >(
    ['updateDomainInfo'],
    (variables?: Types.UpdateDomainInfoMutationVariables) =>
      fetcher<Types.UpdateDomainInfoMutation, Types.UpdateDomainInfoMutationVariables>(
        UpdateDomainInfoDocument,
        variables,
      )(),
    options,
  );
export const UpdateMonitorDocument = `
    mutation updateMonitor($id: String!, $newUrl: String!) {
  updateMonitor(id: $id, newUrl: $newUrl) {
    ...monitor
  }
}
    ${MonitorFragmentDoc}`;
export const useUpdateMonitorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateMonitorMutation,
    TError,
    Types.UpdateMonitorMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdateMonitorMutation, TError, Types.UpdateMonitorMutationVariables, TContext>(
    ['updateMonitor'],
    (variables?: Types.UpdateMonitorMutationVariables) =>
      fetcher<Types.UpdateMonitorMutation, Types.UpdateMonitorMutationVariables>(
        UpdateMonitorDocument,
        variables,
      )(),
    options,
  );
export const UpdateNotificationSettingDocument = `
    mutation updateNotificationSetting($id: ID!, $channel: String!, $value: Boolean!) {
  updateNotificationSetting(id: $id, channel: $channel, value: $value)
}
    `;
export const useUpdateNotificationSettingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateNotificationSettingMutation,
    TError,
    Types.UpdateNotificationSettingMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateNotificationSettingMutation,
    TError,
    Types.UpdateNotificationSettingMutationVariables,
    TContext
  >(
    ['updateNotificationSetting'],
    (variables?: Types.UpdateNotificationSettingMutationVariables) =>
      fetcher<
        Types.UpdateNotificationSettingMutation,
        Types.UpdateNotificationSettingMutationVariables
      >(UpdateNotificationSettingDocument, variables)(),
    options,
  );
export const UpdatePhraseDocument = `
    mutation updatePhrase($id: String!, $domainId: String, $checkPeriod: Int, $location: String, $groupId: String, $userAssignedUrl: String) {
  updatePhrase(
    id: $id
    domainId: $domainId
    checkPeriod: $checkPeriod
    location: $location
    groupId: $groupId
    userAssignedUrl: $userAssignedUrl
  ) {
    ...phrase
  }
}
    ${PhraseFragmentDoc}`;
export const useUpdatePhraseMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdatePhraseMutation,
    TError,
    Types.UpdatePhraseMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdatePhraseMutation, TError, Types.UpdatePhraseMutationVariables, TContext>(
    ['updatePhrase'],
    (variables?: Types.UpdatePhraseMutationVariables) =>
      fetcher<Types.UpdatePhraseMutation, Types.UpdatePhraseMutationVariables>(
        UpdatePhraseDocument,
        variables,
      )(),
    options,
  );
export const UpdatePhraseGroupDocument = `
    mutation updatePhraseGroup($groupId: String!, $name: String, $phraseIds: [String!]) {
  updatePhraseGroup(groupId: $groupId, name: $name, phraseIds: $phraseIds) {
    ...phraseGroup
  }
}
    ${PhraseGroupFragmentDoc}`;
export const useUpdatePhraseGroupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdatePhraseGroupMutation,
    TError,
    Types.UpdatePhraseGroupMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdatePhraseGroupMutation,
    TError,
    Types.UpdatePhraseGroupMutationVariables,
    TContext
  >(
    ['updatePhraseGroup'],
    (variables?: Types.UpdatePhraseGroupMutationVariables) =>
      fetcher<Types.UpdatePhraseGroupMutation, Types.UpdatePhraseGroupMutationVariables>(
        UpdatePhraseGroupDocument,
        variables,
      )(),
    options,
  );
export const UpdatePhraseUrlDocument = `
    mutation updatePhraseUrl($id: String!, $userAssignedUrl: String) {
  updatePhrase(id: $id, userAssignedUrl: $userAssignedUrl) {
    id
    user_assigned_url
  }
}
    `;
export const useUpdatePhraseUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdatePhraseUrlMutation,
    TError,
    Types.UpdatePhraseUrlMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdatePhraseUrlMutation,
    TError,
    Types.UpdatePhraseUrlMutationVariables,
    TContext
  >(
    ['updatePhraseUrl'],
    (variables?: Types.UpdatePhraseUrlMutationVariables) =>
      fetcher<Types.UpdatePhraseUrlMutation, Types.UpdatePhraseUrlMutationVariables>(
        UpdatePhraseUrlDocument,
        variables,
      )(),
    options,
  );
export const UpdateRoleDocument = `
    mutation updateRole($id: ID!, $title: String!) {
  updateRole(id: $id, title: $title) {
    ...role
  }
}
    ${RoleFragmentDoc}`;
export const useUpdateRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateRoleMutation,
    TError,
    Types.UpdateRoleMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdateRoleMutation, TError, Types.UpdateRoleMutationVariables, TContext>(
    ['updateRole'],
    (variables?: Types.UpdateRoleMutationVariables) =>
      fetcher<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>(
        UpdateRoleDocument,
        variables,
      )(),
    options,
  );
export const UpdateS4sInstanceDocument = `
    mutation updateS4sInstance($id: String!, $name: String, $host: String, $port: Int, $user: String, $password: String, $db: String, $prefix: String) {
  updateS4sInstance(
    id: $id
    name: $name
    host: $host
    port: $port
    user: $user
    password: $password
    db: $db
    prefix: $prefix
  ) {
    ...s4sInstance
  }
}
    ${S4sInstanceFragmentDoc}`;
export const useUpdateS4sInstanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateS4sInstanceMutation,
    TError,
    Types.UpdateS4sInstanceMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateS4sInstanceMutation,
    TError,
    Types.UpdateS4sInstanceMutationVariables,
    TContext
  >(
    ['updateS4sInstance'],
    (variables?: Types.UpdateS4sInstanceMutationVariables) =>
      fetcher<Types.UpdateS4sInstanceMutation, Types.UpdateS4sInstanceMutationVariables>(
        UpdateS4sInstanceDocument,
        variables,
      )(),
    options,
  );
export const UpdateTextOrderDocument = `
    mutation updateTextOrder($id: ID!, $status: TextOrderStatusEnum!) {
  updateTextOrder(id: $id, status: $status) {
    ...textOrderInTheList
  }
}
    ${TextOrderInTheListFragmentDoc}`;
export const useUpdateTextOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTextOrderMutation,
    TError,
    Types.UpdateTextOrderMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateTextOrderMutation,
    TError,
    Types.UpdateTextOrderMutationVariables,
    TContext
  >(
    ['updateTextOrder'],
    (variables?: Types.UpdateTextOrderMutationVariables) =>
      fetcher<Types.UpdateTextOrderMutation, Types.UpdateTextOrderMutationVariables>(
        UpdateTextOrderDocument,
        variables,
      )(),
    options,
  );
export const UpdateTicketDocument = `
    mutation updateTicket($id: ID!, $title: String, $contentHtml: String, $typeId: ID, $statusId: ID, $deadline: DateTime, $start: DateTime, $domainId: ID) {
  updateTicket(
    id: $id
    title: $title
    contentHtml: $contentHtml
    typeId: $typeId
    statusId: $statusId
    deadline: $deadline
    start: $start
    domainId: $domainId
  ) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useUpdateTicketMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTicketMutation,
    TError,
    Types.UpdateTicketMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdateTicketMutation, TError, Types.UpdateTicketMutationVariables, TContext>(
    ['updateTicket'],
    (variables?: Types.UpdateTicketMutationVariables) =>
      fetcher<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>(
        UpdateTicketDocument,
        variables,
      )(),
    options,
  );
export const UpdateTicketCommentDocument = `
    mutation updateTicketComment($id: ID!, $contentHtml: String!) {
  updateTicketComment(id: $id, contentHtml: $contentHtml) {
    ...ticketComment
  }
}
    ${TicketCommentFragmentDoc}
${UserBasicFragmentDoc}
${TicketCommentFileFragmentDoc}`;
export const useUpdateTicketCommentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTicketCommentMutation,
    TError,
    Types.UpdateTicketCommentMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateTicketCommentMutation,
    TError,
    Types.UpdateTicketCommentMutationVariables,
    TContext
  >(
    ['updateTicketComment'],
    (variables?: Types.UpdateTicketCommentMutationVariables) =>
      fetcher<Types.UpdateTicketCommentMutation, Types.UpdateTicketCommentMutationVariables>(
        UpdateTicketCommentDocument,
        variables,
      )(),
    options,
  );
export const UpdateTicketStatusDocument = `
    mutation updateTicketStatus($id: ID!, $value: String, $treatAsClosed: Boolean, $archived: Boolean, $color: ColorPaletteEnum, $visibleInKanban: Boolean, $priority: Float) {
  updateTicketStatus(
    id: $id
    value: $value
    treatAsClosed: $treatAsClosed
    archived: $archived
    color: $color
    visibleInKanban: $visibleInKanban
    priority: $priority
  ) {
    ...status
  }
}
    ${StatusFragmentDoc}`;
export const useUpdateTicketStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTicketStatusMutation,
    TError,
    Types.UpdateTicketStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateTicketStatusMutation,
    TError,
    Types.UpdateTicketStatusMutationVariables,
    TContext
  >(
    ['updateTicketStatus'],
    (variables?: Types.UpdateTicketStatusMutationVariables) =>
      fetcher<Types.UpdateTicketStatusMutation, Types.UpdateTicketStatusMutationVariables>(
        UpdateTicketStatusDocument,
        variables,
      )(),
    options,
  );
export const UpdateTicketTypeDocument = `
    mutation updateTicketType($id: ID!, $value: String, $archived: Boolean, $color: ColorPaletteEnum) {
  updateTicketType(id: $id, value: $value, archived: $archived, color: $color) {
    ...type
  }
}
    ${TypeFragmentDoc}`;
export const useUpdateTicketTypeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTicketTypeMutation,
    TError,
    Types.UpdateTicketTypeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateTicketTypeMutation,
    TError,
    Types.UpdateTicketTypeMutationVariables,
    TContext
  >(
    ['updateTicketType'],
    (variables?: Types.UpdateTicketTypeMutationVariables) =>
      fetcher<Types.UpdateTicketTypeMutation, Types.UpdateTicketTypeMutationVariables>(
        UpdateTicketTypeDocument,
        variables,
      )(),
    options,
  );
export const UpdateTodoDocument = `
    mutation updateTodo($id: ID!, $text: String, $priority: Float, $status: String) {
  updateTodo(id: $id, text: $text, priority: $priority, status: $status) {
    ...ticketTodo
  }
}
    ${TicketTodoFragmentDoc}`;
export const useUpdateTodoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateTodoMutation,
    TError,
    Types.UpdateTodoMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdateTodoMutation, TError, Types.UpdateTodoMutationVariables, TContext>(
    ['updateTodo'],
    (variables?: Types.UpdateTodoMutationVariables) =>
      fetcher<Types.UpdateTodoMutation, Types.UpdateTodoMutationVariables>(
        UpdateTodoDocument,
        variables,
      )(),
    options,
  );
export const UpdateUserDocument = `
    mutation updateUser($id: ID!, $userInput: UpdateUserInput!) {
  updateUser(id: $id, userInput: $userInput) {
    ...user
  }
}
    ${UserFragmentDoc}
${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateUserMutation,
    TError,
    Types.UpdateUserMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.UpdateUserMutation, TError, Types.UpdateUserMutationVariables, TContext>(
    ['updateUser'],
    (variables?: Types.UpdateUserMutationVariables) =>
      fetcher<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(
        UpdateUserDocument,
        variables,
      )(),
    options,
  );
export const UpdateUserDomainNotificationsDocument = `
    mutation updateUserDomainNotifications($id: ID!, $domainId: String!, $isSubscribed: Boolean!) {
  updateUserDomainNotifications(
    userId: $id
    domainId: $domainId
    isSubscribed: $isSubscribed
  ) {
    ...user
  }
}
    ${UserFragmentDoc}
${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useUpdateUserDomainNotificationsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.UpdateUserDomainNotificationsMutation,
    TError,
    Types.UpdateUserDomainNotificationsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    Types.UpdateUserDomainNotificationsMutation,
    TError,
    Types.UpdateUserDomainNotificationsMutationVariables,
    TContext
  >(
    ['updateUserDomainNotifications'],
    (variables?: Types.UpdateUserDomainNotificationsMutationVariables) =>
      fetcher<
        Types.UpdateUserDomainNotificationsMutation,
        Types.UpdateUserDomainNotificationsMutationVariables
      >(UpdateUserDomainNotificationsDocument, variables)(),
    options,
  );
export const WatchTicketDocument = `
    mutation watchTicket($ticketId: ID!, $userIds: [ID!]) {
  watchTicket(ticketId: $ticketId, userIds: $userIds) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useWatchTicketMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Types.WatchTicketMutation,
    TError,
    Types.WatchTicketMutationVariables,
    TContext
  >,
) =>
  useMutation<Types.WatchTicketMutation, TError, Types.WatchTicketMutationVariables, TContext>(
    ['watchTicket'],
    (variables?: Types.WatchTicketMutationVariables) =>
      fetcher<Types.WatchTicketMutation, Types.WatchTicketMutationVariables>(
        WatchTicketDocument,
        variables,
      )(),
    options,
  );
export const ActiveUserDocument = `
    query activeUser {
  activeUser {
    ...activeUser
  }
}
    ${ActiveUserFragmentDoc}
${UserBasicFragmentDoc}`;
export const useActiveUserQuery = <TData = Types.ActiveUserQuery, TError = unknown>(
  variables?: Types.ActiveUserQueryVariables,
  options?: UseQueryOptions<Types.ActiveUserQuery, TError, TData>,
) =>
  useQuery<Types.ActiveUserQuery, TError, TData>(
    variables === undefined ? ['activeUser'] : ['activeUser', variables],
    fetcher<Types.ActiveUserQuery, Types.ActiveUserQueryVariables>(ActiveUserDocument, variables),
    options,
  );
export const ClientSettingsDocument = `
    query clientSettings($id: ID!) {
  user(id: $id) {
    ...clientSettings
  }
}
    ${ClientSettingsFragmentDoc}`;
export const useClientSettingsQuery = <TData = Types.ClientSettingsQuery, TError = unknown>(
  variables: Types.ClientSettingsQueryVariables,
  options?: UseQueryOptions<Types.ClientSettingsQuery, TError, TData>,
) =>
  useQuery<Types.ClientSettingsQuery, TError, TData>(
    ['clientSettings', variables],
    fetcher<Types.ClientSettingsQuery, Types.ClientSettingsQueryVariables>(
      ClientSettingsDocument,
      variables,
    ),
    options,
  );
export const ColorPaletteDocument = `
    query colorPalette {
  colorPalette {
    id
    key
  }
}
    `;
export const useColorPaletteQuery = <TData = Types.ColorPaletteQuery, TError = unknown>(
  variables?: Types.ColorPaletteQueryVariables,
  options?: UseQueryOptions<Types.ColorPaletteQuery, TError, TData>,
) =>
  useQuery<Types.ColorPaletteQuery, TError, TData>(
    variables === undefined ? ['colorPalette'] : ['colorPalette', variables],
    fetcher<Types.ColorPaletteQuery, Types.ColorPaletteQueryVariables>(
      ColorPaletteDocument,
      variables,
    ),
    options,
  );
export const CountriesDocument = `
    query countries {
  countries {
    id
    key
    value
  }
}
    `;
export const useCountriesQuery = <TData = Types.CountriesQuery, TError = unknown>(
  variables?: Types.CountriesQueryVariables,
  options?: UseQueryOptions<Types.CountriesQuery, TError, TData>,
) =>
  useQuery<Types.CountriesQuery, TError, TData>(
    variables === undefined ? ['countries'] : ['countries', variables],
    fetcher<Types.CountriesQuery, Types.CountriesQueryVariables>(CountriesDocument, variables),
    options,
  );
export const CrmCompaniesDocument = `
    query crmCompanies {
  crmCompanies {
    id
    fullName
  }
}
    `;
export const useCrmCompaniesQuery = <TData = Types.CrmCompaniesQuery, TError = unknown>(
  variables?: Types.CrmCompaniesQueryVariables,
  options?: UseQueryOptions<Types.CrmCompaniesQuery, TError, TData>,
) =>
  useQuery<Types.CrmCompaniesQuery, TError, TData>(
    variables === undefined ? ['crmCompanies'] : ['crmCompanies', variables],
    fetcher<Types.CrmCompaniesQuery, Types.CrmCompaniesQueryVariables>(
      CrmCompaniesDocument,
      variables,
    ),
    options,
  );
export const DealDocument = `
    query deal($id: ID!) {
  deal(id: $id) {
    ...deal
  }
}
    ${DealFragmentDoc}`;
export const useDealQuery = <TData = Types.DealQuery, TError = unknown>(
  variables: Types.DealQueryVariables,
  options?: UseQueryOptions<Types.DealQuery, TError, TData>,
) =>
  useQuery<Types.DealQuery, TError, TData>(
    ['deal', variables],
    fetcher<Types.DealQuery, Types.DealQueryVariables>(DealDocument, variables),
    options,
  );
export const DealTypesDocument = `
    query dealTypes {
  dealTypes {
    id
    key
    value
  }
}
    `;
export const useDealTypesQuery = <TData = Types.DealTypesQuery, TError = unknown>(
  variables?: Types.DealTypesQueryVariables,
  options?: UseQueryOptions<Types.DealTypesQuery, TError, TData>,
) =>
  useQuery<Types.DealTypesQuery, TError, TData>(
    variables === undefined ? ['dealTypes'] : ['dealTypes', variables],
    fetcher<Types.DealTypesQuery, Types.DealTypesQueryVariables>(DealTypesDocument, variables),
    options,
  );
export const DomainDocument = `
    query domain($id: ID!) {
  domain(id: $id) {
    ...domain
  }
}
    ${DomainFragmentDoc}
${DealFragmentDoc}`;
export const useDomainQuery = <TData = Types.DomainQuery, TError = unknown>(
  variables: Types.DomainQueryVariables,
  options?: UseQueryOptions<Types.DomainQuery, TError, TData>,
) =>
  useQuery<Types.DomainQuery, TError, TData>(
    ['domain', variables],
    fetcher<Types.DomainQuery, Types.DomainQueryVariables>(DomainDocument, variables),
    options,
  );
export const DomainAdminDocument = `
    query domainADMIN($id: ID!) {
  domain(id: $id) {
    ...domainADMIN
  }
}
    ${DomainAdminFragmentDoc}
${PartnerUserFragmentDoc}
${UserBasicFragmentDoc}
${UserFragmentDoc}
${UserInTheListFragmentDoc}
${DealAdminFragmentDoc}
${DealFragmentDoc}`;
export const useDomainAdminQuery = <TData = Types.DomainAdminQuery, TError = unknown>(
  variables: Types.DomainAdminQueryVariables,
  options?: UseQueryOptions<Types.DomainAdminQuery, TError, TData>,
) =>
  useQuery<Types.DomainAdminQuery, TError, TData>(
    ['domainADMIN', variables],
    fetcher<Types.DomainAdminQuery, Types.DomainAdminQueryVariables>(
      DomainAdminDocument,
      variables,
    ),
    options,
  );
export const DomainListDocument = `
    query domainList($grid: DomainGridInput) {
  domains(grid: $grid) {
    total
    rows {
      ...domainInTheList
    }
  }
}
    ${DomainInTheListFragmentDoc}
${UserBasicFragmentDoc}
${DealInTheDomainListFragmentDoc}`;
export const useDomainListQuery = <TData = Types.DomainListQuery, TError = unknown>(
  variables?: Types.DomainListQueryVariables,
  options?: UseQueryOptions<Types.DomainListQuery, TError, TData>,
) =>
  useQuery<Types.DomainListQuery, TError, TData>(
    variables === undefined ? ['domainList'] : ['domainList', variables],
    fetcher<Types.DomainListQuery, Types.DomainListQueryVariables>(DomainListDocument, variables),
    options,
  );
export const DomainListAdminDocument = `
    query domainListADMIN($grid: DomainGridInput) {
  domains(grid: $grid) {
    total
    rows {
      ...domainInTheListADMIN
    }
  }
}
    ${DomainInTheListAdminFragmentDoc}
${PartnerUserFragmentDoc}
${UserBasicFragmentDoc}
${DealInTheDomainListAdminFragmentDoc}
${DealInTheDomainListFragmentDoc}`;
export const useDomainListAdminQuery = <TData = Types.DomainListAdminQuery, TError = unknown>(
  variables?: Types.DomainListAdminQueryVariables,
  options?: UseQueryOptions<Types.DomainListAdminQuery, TError, TData>,
) =>
  useQuery<Types.DomainListAdminQuery, TError, TData>(
    variables === undefined ? ['domainListADMIN'] : ['domainListADMIN', variables],
    fetcher<Types.DomainListAdminQuery, Types.DomainListAdminQueryVariables>(
      DomainListAdminDocument,
      variables,
    ),
    options,
  );
export const DomainNamesDocument = `
    query domainNames($grid: DomainGridInput) {
  domains(grid: $grid) {
    rows {
      id
      name
    }
  }
}
    `;
export const useDomainNamesQuery = <TData = Types.DomainNamesQuery, TError = unknown>(
  variables?: Types.DomainNamesQueryVariables,
  options?: UseQueryOptions<Types.DomainNamesQuery, TError, TData>,
) =>
  useQuery<Types.DomainNamesQuery, TError, TData>(
    variables === undefined ? ['domainNames'] : ['domainNames', variables],
    fetcher<Types.DomainNamesQuery, Types.DomainNamesQueryVariables>(
      DomainNamesDocument,
      variables,
    ),
    options,
  );
export const DomainsDocument = `
    query domains($grid: DomainGridInput) {
  domains(grid: $grid) {
    rows {
      ...domainBasic
    }
  }
}
    ${DomainBasicFragmentDoc}`;
export const useDomainsQuery = <TData = Types.DomainsQuery, TError = unknown>(
  variables?: Types.DomainsQueryVariables,
  options?: UseQueryOptions<Types.DomainsQuery, TError, TData>,
) =>
  useQuery<Types.DomainsQuery, TError, TData>(
    variables === undefined ? ['domains'] : ['domains', variables],
    fetcher<Types.DomainsQuery, Types.DomainsQueryVariables>(DomainsDocument, variables),
    options,
  );
export const DomainsWithAccountIdDocument = `
    query domainsWithAccountId {
  domainsWithAccountId {
    id
    name
    account_id
  }
}
    `;
export const useDomainsWithAccountIdQuery = <
  TData = Types.DomainsWithAccountIdQuery,
  TError = unknown,
>(
  variables?: Types.DomainsWithAccountIdQueryVariables,
  options?: UseQueryOptions<Types.DomainsWithAccountIdQuery, TError, TData>,
) =>
  useQuery<Types.DomainsWithAccountIdQuery, TError, TData>(
    variables === undefined ? ['domainsWithAccountId'] : ['domainsWithAccountId', variables],
    fetcher<Types.DomainsWithAccountIdQuery, Types.DomainsWithAccountIdQueryVariables>(
      DomainsWithAccountIdDocument,
      variables,
    ),
    options,
  );
export const EventTypesDocument = `
    query eventTypes {
  eventTypes
}
    `;
export const useEventTypesQuery = <TData = Types.EventTypesQuery, TError = unknown>(
  variables?: Types.EventTypesQueryVariables,
  options?: UseQueryOptions<Types.EventTypesQuery, TError, TData>,
) =>
  useQuery<Types.EventTypesQuery, TError, TData>(
    variables === undefined ? ['eventTypes'] : ['eventTypes', variables],
    fetcher<Types.EventTypesQuery, Types.EventTypesQueryVariables>(EventTypesDocument, variables),
    options,
  );
export const ExternalReportsDocument = `
    query externalReports($domainId: ID) {
  externalReports(domainId: $domainId) {
    ...externalReport
  }
}
    ${ExternalReportFragmentDoc}`;
export const useExternalReportsQuery = <TData = Types.ExternalReportsQuery, TError = unknown>(
  variables?: Types.ExternalReportsQueryVariables,
  options?: UseQueryOptions<Types.ExternalReportsQuery, TError, TData>,
) =>
  useQuery<Types.ExternalReportsQuery, TError, TData>(
    variables === undefined ? ['externalReports'] : ['externalReports', variables],
    fetcher<Types.ExternalReportsQuery, Types.ExternalReportsQueryVariables>(
      ExternalReportsDocument,
      variables,
    ),
    options,
  );
export const FilesDocument = `
    query files {
  files {
    id
    title
    url
  }
}
    `;
export const useFilesQuery = <TData = Types.FilesQuery, TError = unknown>(
  variables?: Types.FilesQueryVariables,
  options?: UseQueryOptions<Types.FilesQuery, TError, TData>,
) =>
  useQuery<Types.FilesQuery, TError, TData>(
    variables === undefined ? ['files'] : ['files', variables],
    fetcher<Types.FilesQuery, Types.FilesQueryVariables>(FilesDocument, variables),
    options,
  );
export const GlobalSearchDocument = `
    query globalSearch($domainsGrid: DomainGridInput) {
  domains(grid: $domainsGrid) {
    rows {
      id
      name
    }
  }
}
    `;
export const useGlobalSearchQuery = <TData = Types.GlobalSearchQuery, TError = unknown>(
  variables?: Types.GlobalSearchQueryVariables,
  options?: UseQueryOptions<Types.GlobalSearchQuery, TError, TData>,
) =>
  useQuery<Types.GlobalSearchQuery, TError, TData>(
    variables === undefined ? ['globalSearch'] : ['globalSearch', variables],
    fetcher<Types.GlobalSearchQuery, Types.GlobalSearchQueryVariables>(
      GlobalSearchDocument,
      variables,
    ),
    options,
  );
export const GroupedPermissionsDocument = `
    query groupedPermissions {
  groupedPermissions {
    ...groupedPermissions
  }
}
    ${GroupedPermissionsFragmentDoc}
${PermissionFragmentDoc}`;
export const useGroupedPermissionsQuery = <TData = Types.GroupedPermissionsQuery, TError = unknown>(
  variables?: Types.GroupedPermissionsQueryVariables,
  options?: UseQueryOptions<Types.GroupedPermissionsQuery, TError, TData>,
) =>
  useQuery<Types.GroupedPermissionsQuery, TError, TData>(
    variables === undefined ? ['groupedPermissions'] : ['groupedPermissions', variables],
    fetcher<Types.GroupedPermissionsQuery, Types.GroupedPermissionsQueryVariables>(
      GroupedPermissionsDocument,
      variables,
    ),
    options,
  );
export const GroupedPhrasesDocument = `
    query groupedPhrases($grid: GroupedPhraseGridInput) {
  groupedPhrases(grid: $grid) {
    ...groupedPhrases
  }
}
    ${GroupedPhrasesFragmentDoc}
${PhraseFragmentDoc}`;
export const useGroupedPhrasesQuery = <TData = Types.GroupedPhrasesQuery, TError = unknown>(
  variables?: Types.GroupedPhrasesQueryVariables,
  options?: UseQueryOptions<Types.GroupedPhrasesQuery, TError, TData>,
) =>
  useQuery<Types.GroupedPhrasesQuery, TError, TData>(
    variables === undefined ? ['groupedPhrases'] : ['groupedPhrases', variables],
    fetcher<Types.GroupedPhrasesQuery, Types.GroupedPhrasesQueryVariables>(
      GroupedPhrasesDocument,
      variables,
    ),
    options,
  );
export const ImportedTicketDocument = `
    query importedTicket($id: ID!) {
  ticket(id: $id) {
    ...importedTicket
  }
}
    ${ImportedTicketFragmentDoc}
${TicketCommentFileFragmentDoc}
${ImportedTicketCommentFragmentDoc}
${TicketFileFragmentDoc}
${DomainBasicFragmentDoc}`;
export const useImportedTicketQuery = <TData = Types.ImportedTicketQuery, TError = unknown>(
  variables: Types.ImportedTicketQueryVariables,
  options?: UseQueryOptions<Types.ImportedTicketQuery, TError, TData>,
) =>
  useQuery<Types.ImportedTicketQuery, TError, TData>(
    ['importedTicket', variables],
    fetcher<Types.ImportedTicketQuery, Types.ImportedTicketQueryVariables>(
      ImportedTicketDocument,
      variables,
    ),
    options,
  );
export const LocalesDocument = `
    query locales {
  locales {
    id
    key
    value
  }
}
    `;
export const useLocalesQuery = <TData = Types.LocalesQuery, TError = unknown>(
  variables?: Types.LocalesQueryVariables,
  options?: UseQueryOptions<Types.LocalesQuery, TError, TData>,
) =>
  useQuery<Types.LocalesQuery, TError, TData>(
    variables === undefined ? ['locales'] : ['locales', variables],
    fetcher<Types.LocalesQuery, Types.LocalesQueryVariables>(LocalesDocument, variables),
    options,
  );
export const LocationsDocument = `
    query locations {
  locations {
    id
  }
}
    `;
export const useLocationsQuery = <TData = Types.LocationsQuery, TError = unknown>(
  variables?: Types.LocationsQueryVariables,
  options?: UseQueryOptions<Types.LocationsQuery, TError, TData>,
) =>
  useQuery<Types.LocationsQuery, TError, TData>(
    variables === undefined ? ['locations'] : ['locations', variables],
    fetcher<Types.LocationsQuery, Types.LocationsQueryVariables>(LocationsDocument, variables),
    options,
  );
export const MonitorDocument = `
    query monitor($id: String!) {
  monitor(id: $id) {
    ...monitor
  }
}
    ${MonitorFragmentDoc}`;
export const useMonitorQuery = <TData = Types.MonitorQuery, TError = unknown>(
  variables: Types.MonitorQueryVariables,
  options?: UseQueryOptions<Types.MonitorQuery, TError, TData>,
) =>
  useQuery<Types.MonitorQuery, TError, TData>(
    ['monitor', variables],
    fetcher<Types.MonitorQuery, Types.MonitorQueryVariables>(MonitorDocument, variables),
    options,
  );
export const MonitorLimitsDocument = `
    query monitorLimits {
  monitorLimits {
    monitors
    monitorsLimit
    upMonitors
    downMonitors
  }
}
    `;
export const useMonitorLimitsQuery = <TData = Types.MonitorLimitsQuery, TError = unknown>(
  variables?: Types.MonitorLimitsQueryVariables,
  options?: UseQueryOptions<Types.MonitorLimitsQuery, TError, TData>,
) =>
  useQuery<Types.MonitorLimitsQuery, TError, TData>(
    variables === undefined ? ['monitorLimits'] : ['monitorLimits', variables],
    fetcher<Types.MonitorLimitsQuery, Types.MonitorLimitsQueryVariables>(
      MonitorLimitsDocument,
      variables,
    ),
    options,
  );
export const MonitorsDocument = `
    query monitors($grid: MonitorGridInput) {
  monitors(grid: $grid) {
    total
    rows {
      ...monitor
    }
  }
}
    ${MonitorFragmentDoc}`;
export const useMonitorsQuery = <TData = Types.MonitorsQuery, TError = unknown>(
  variables?: Types.MonitorsQueryVariables,
  options?: UseQueryOptions<Types.MonitorsQuery, TError, TData>,
) =>
  useQuery<Types.MonitorsQuery, TError, TData>(
    variables === undefined ? ['monitors'] : ['monitors', variables],
    fetcher<Types.MonitorsQuery, Types.MonitorsQueryVariables>(MonitorsDocument, variables),
    options,
  );
export const NotificationGroupsDocument = `
    query notificationGroups {
  notificationGroups {
    id
    key
    value
  }
}
    `;
export const useNotificationGroupsQuery = <TData = Types.NotificationGroupsQuery, TError = unknown>(
  variables?: Types.NotificationGroupsQueryVariables,
  options?: UseQueryOptions<Types.NotificationGroupsQuery, TError, TData>,
) =>
  useQuery<Types.NotificationGroupsQuery, TError, TData>(
    variables === undefined ? ['notificationGroups'] : ['notificationGroups', variables],
    fetcher<Types.NotificationGroupsQuery, Types.NotificationGroupsQueryVariables>(
      NotificationGroupsDocument,
      variables,
    ),
    options,
  );
export const NotificationSettingsDocument = `
    query notificationSettings {
  notificationSettings {
    group
    groupName
    settings {
      id
      key
      name
      channel
      value
      group
    }
  }
}
    `;
export const useNotificationSettingsQuery = <
  TData = Types.NotificationSettingsQuery,
  TError = unknown,
>(
  variables?: Types.NotificationSettingsQueryVariables,
  options?: UseQueryOptions<Types.NotificationSettingsQuery, TError, TData>,
) =>
  useQuery<Types.NotificationSettingsQuery, TError, TData>(
    variables === undefined ? ['notificationSettings'] : ['notificationSettings', variables],
    fetcher<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>(
      NotificationSettingsDocument,
      variables,
    ),
    options,
  );
export const PermissionsDocument = `
    query permissions {
  permissions {
    id
    key
    value
  }
}
    `;
export const usePermissionsQuery = <TData = Types.PermissionsQuery, TError = unknown>(
  variables?: Types.PermissionsQueryVariables,
  options?: UseQueryOptions<Types.PermissionsQuery, TError, TData>,
) =>
  useQuery<Types.PermissionsQuery, TError, TData>(
    variables === undefined ? ['permissions'] : ['permissions', variables],
    fetcher<Types.PermissionsQuery, Types.PermissionsQueryVariables>(
      PermissionsDocument,
      variables,
    ),
    options,
  );
export const PhraseGroupsDocument = `
    query phraseGroups($domainId: String!) {
  phraseGroups(domainId: $domainId) {
    ...phraseGroup
  }
}
    ${PhraseGroupFragmentDoc}`;
export const usePhraseGroupsQuery = <TData = Types.PhraseGroupsQuery, TError = unknown>(
  variables: Types.PhraseGroupsQueryVariables,
  options?: UseQueryOptions<Types.PhraseGroupsQuery, TError, TData>,
) =>
  useQuery<Types.PhraseGroupsQuery, TError, TData>(
    ['phraseGroups', variables],
    fetcher<Types.PhraseGroupsQuery, Types.PhraseGroupsQueryVariables>(
      PhraseGroupsDocument,
      variables,
    ),
    options,
  );
export const PhrasesDocument = `
    query phrases($grid: PhraseGridInput) {
  phrases(grid: $grid) {
    total
    rows {
      ...phrase
    }
  }
}
    ${PhraseFragmentDoc}`;
export const usePhrasesQuery = <TData = Types.PhrasesQuery, TError = unknown>(
  variables?: Types.PhrasesQueryVariables,
  options?: UseQueryOptions<Types.PhrasesQuery, TError, TData>,
) =>
  useQuery<Types.PhrasesQuery, TError, TData>(
    variables === undefined ? ['phrases'] : ['phrases', variables],
    fetcher<Types.PhrasesQuery, Types.PhrasesQueryVariables>(PhrasesDocument, variables),
    options,
  );
export const RegionsDocument = `
    query regions($country: String!) {
  regions(country: $country)
}
    `;
export const useRegionsQuery = <TData = Types.RegionsQuery, TError = unknown>(
  variables: Types.RegionsQueryVariables,
  options?: UseQueryOptions<Types.RegionsQuery, TError, TData>,
) =>
  useQuery<Types.RegionsQuery, TError, TData>(
    ['regions', variables],
    fetcher<Types.RegionsQuery, Types.RegionsQueryVariables>(RegionsDocument, variables),
    options,
  );
export const RolesDocument = `
    query roles {
  roles {
    ...roleInTheList
  }
}
    ${RoleInTheListFragmentDoc}`;
export const useRolesQuery = <TData = Types.RolesQuery, TError = unknown>(
  variables?: Types.RolesQueryVariables,
  options?: UseQueryOptions<Types.RolesQuery, TError, TData>,
) =>
  useQuery<Types.RolesQuery, TError, TData>(
    variables === undefined ? ['roles'] : ['roles', variables],
    fetcher<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, variables),
    options,
  );
export const S4sInstanceDocument = `
    query s4sInstance($id: ID!) {
  s4sInstance(id: $id) {
    ...s4sInstance
  }
}
    ${S4sInstanceFragmentDoc}`;
export const useS4sInstanceQuery = <TData = Types.S4sInstanceQuery, TError = unknown>(
  variables: Types.S4sInstanceQueryVariables,
  options?: UseQueryOptions<Types.S4sInstanceQuery, TError, TData>,
) =>
  useQuery<Types.S4sInstanceQuery, TError, TData>(
    ['s4sInstance', variables],
    fetcher<Types.S4sInstanceQuery, Types.S4sInstanceQueryVariables>(
      S4sInstanceDocument,
      variables,
    ),
    options,
  );
export const S4sInstancesDocument = `
    query s4sInstances($grid: S4sGridInput) {
  s4sInstances(grid: $grid) {
    total
    rows {
      ...s4sInstance
    }
  }
}
    ${S4sInstanceFragmentDoc}`;
export const useS4sInstancesQuery = <TData = Types.S4sInstancesQuery, TError = unknown>(
  variables?: Types.S4sInstancesQueryVariables,
  options?: UseQueryOptions<Types.S4sInstancesQuery, TError, TData>,
) =>
  useQuery<Types.S4sInstancesQuery, TError, TData>(
    variables === undefined ? ['s4sInstances'] : ['s4sInstances', variables],
    fetcher<Types.S4sInstancesQuery, Types.S4sInstancesQueryVariables>(
      S4sInstancesDocument,
      variables,
    ),
    options,
  );
export const TextOrdersDocument = `
    query textOrders($grid: TextOrderGridInput) {
  textOrders(grid: $grid) {
    total
    rows {
      ...textOrderInTheList
    }
  }
}
    ${TextOrderInTheListFragmentDoc}`;
export const useTextOrdersQuery = <TData = Types.TextOrdersQuery, TError = unknown>(
  variables?: Types.TextOrdersQueryVariables,
  options?: UseQueryOptions<Types.TextOrdersQuery, TError, TData>,
) =>
  useQuery<Types.TextOrdersQuery, TError, TData>(
    variables === undefined ? ['textOrders'] : ['textOrders', variables],
    fetcher<Types.TextOrdersQuery, Types.TextOrdersQueryVariables>(TextOrdersDocument, variables),
    options,
  );
export const TicketDocument = `
    query ticket($id: ID!) {
  ticket(id: $id) {
    ...ticket
  }
}
    ${TicketFragmentDoc}
${DomainBasicFragmentDoc}
${TicketFileFragmentDoc}`;
export const useTicketQuery = <TData = Types.TicketQuery, TError = unknown>(
  variables: Types.TicketQueryVariables,
  options?: UseQueryOptions<Types.TicketQuery, TError, TData>,
) =>
  useQuery<Types.TicketQuery, TError, TData>(
    ['ticket', variables],
    fetcher<Types.TicketQuery, Types.TicketQueryVariables>(TicketDocument, variables),
    options,
  );
export const TicketActivitiesDocument = `
    query ticketActivities($grid: TicketActivitiesGridInput) {
  ticketActivities(grid: $grid) {
    total
    rows {
      ...activity
    }
  }
}
    ${ActivityFragmentDoc}
${UserBasicFragmentDoc}`;
export const useTicketActivitiesQuery = <TData = Types.TicketActivitiesQuery, TError = unknown>(
  variables?: Types.TicketActivitiesQueryVariables,
  options?: UseQueryOptions<Types.TicketActivitiesQuery, TError, TData>,
) =>
  useQuery<Types.TicketActivitiesQuery, TError, TData>(
    variables === undefined ? ['ticketActivities'] : ['ticketActivities', variables],
    fetcher<Types.TicketActivitiesQuery, Types.TicketActivitiesQueryVariables>(
      TicketActivitiesDocument,
      variables,
    ),
    options,
  );
export const TicketCommentsDocument = `
    query ticketComments($grid: TicketCommentsGridInput) {
  ticketComments(grid: $grid) {
    total
    rows {
      ...ticketComment
    }
  }
}
    ${TicketCommentFragmentDoc}
${UserBasicFragmentDoc}
${TicketCommentFileFragmentDoc}`;
export const useTicketCommentsQuery = <TData = Types.TicketCommentsQuery, TError = unknown>(
  variables?: Types.TicketCommentsQueryVariables,
  options?: UseQueryOptions<Types.TicketCommentsQuery, TError, TData>,
) =>
  useQuery<Types.TicketCommentsQuery, TError, TData>(
    variables === undefined ? ['ticketComments'] : ['ticketComments', variables],
    fetcher<Types.TicketCommentsQuery, Types.TicketCommentsQueryVariables>(
      TicketCommentsDocument,
      variables,
    ),
    options,
  );
export const TicketsDocument = `
    query tickets($grid: TicketGridInput) {
  tickets(grid: $grid) {
    total
    rows {
      ...ticketInTheList
    }
  }
}
    ${TicketInTheListFragmentDoc}
${DomainBasicFragmentDoc}`;
export const useTicketsQuery = <TData = Types.TicketsQuery, TError = unknown>(
  variables?: Types.TicketsQueryVariables,
  options?: UseQueryOptions<Types.TicketsQuery, TError, TData>,
) =>
  useQuery<Types.TicketsQuery, TError, TData>(
    variables === undefined ? ['tickets'] : ['tickets', variables],
    fetcher<Types.TicketsQuery, Types.TicketsQueryVariables>(TicketsDocument, variables),
    options,
  );
export const TicketStatusesDocument = `
    query ticketStatuses($includeArchived: Boolean) {
  ticketStatuses(includeArchived: $includeArchived) {
    ...status
  }
}
    ${StatusFragmentDoc}`;
export const useTicketStatusesQuery = <TData = Types.TicketStatusesQuery, TError = unknown>(
  variables?: Types.TicketStatusesQueryVariables,
  options?: UseQueryOptions<Types.TicketStatusesQuery, TError, TData>,
) =>
  useQuery<Types.TicketStatusesQuery, TError, TData>(
    variables === undefined ? ['ticketStatuses'] : ['ticketStatuses', variables],
    fetcher<Types.TicketStatusesQuery, Types.TicketStatusesQueryVariables>(
      TicketStatusesDocument,
      variables,
    ),
    options,
  );
export const TicketTodosDocument = `
    query ticketTodos($ticketId: ID!) {
  ticketTodos(ticketId: $ticketId) {
    ...ticketTodo
  }
}
    ${TicketTodoFragmentDoc}`;
export const useTicketTodosQuery = <TData = Types.TicketTodosQuery, TError = unknown>(
  variables: Types.TicketTodosQueryVariables,
  options?: UseQueryOptions<Types.TicketTodosQuery, TError, TData>,
) =>
  useQuery<Types.TicketTodosQuery, TError, TData>(
    ['ticketTodos', variables],
    fetcher<Types.TicketTodosQuery, Types.TicketTodosQueryVariables>(
      TicketTodosDocument,
      variables,
    ),
    options,
  );
export const TicketTypesDocument = `
    query ticketTypes($includeArchived: Boolean) {
  ticketTypes(includeArchived: $includeArchived) {
    ...type
  }
}
    ${TypeFragmentDoc}`;
export const useTicketTypesQuery = <TData = Types.TicketTypesQuery, TError = unknown>(
  variables?: Types.TicketTypesQueryVariables,
  options?: UseQueryOptions<Types.TicketTypesQuery, TError, TData>,
) =>
  useQuery<Types.TicketTypesQuery, TError, TData>(
    variables === undefined ? ['ticketTypes'] : ['ticketTypes', variables],
    fetcher<Types.TicketTypesQuery, Types.TicketTypesQueryVariables>(
      TicketTypesDocument,
      variables,
    ),
    options,
  );
export const TicketUsersDocument = `
    query ticketUsers($id: ID!) {
  ticket(id: $id) {
    ...ticketUsers
  }
}
    ${TicketUsersFragmentDoc}
${UserBasicFragmentDoc}`;
export const useTicketUsersQuery = <TData = Types.TicketUsersQuery, TError = unknown>(
  variables: Types.TicketUsersQueryVariables,
  options?: UseQueryOptions<Types.TicketUsersQuery, TError, TData>,
) =>
  useQuery<Types.TicketUsersQuery, TError, TData>(
    ['ticketUsers', variables],
    fetcher<Types.TicketUsersQuery, Types.TicketUsersQueryVariables>(
      TicketUsersDocument,
      variables,
    ),
    options,
  );
export const TicketWatchersDocument = `
    query ticketWatchers($ticketId: ID!) {
  ticketWatchers(id: $ticketId) {
    ...userBasic
  }
}
    ${UserBasicFragmentDoc}`;
export const useTicketWatchersQuery = <TData = Types.TicketWatchersQuery, TError = unknown>(
  variables: Types.TicketWatchersQueryVariables,
  options?: UseQueryOptions<Types.TicketWatchersQuery, TError, TData>,
) =>
  useQuery<Types.TicketWatchersQuery, TError, TData>(
    ['ticketWatchers', variables],
    fetcher<Types.TicketWatchersQuery, Types.TicketWatchersQueryVariables>(
      TicketWatchersDocument,
      variables,
    ),
    options,
  );
export const TranslatablesDocument = `
    query translatables {
  translatables {
    id
    value
    rows {
      id
      type
      translations {
        language_code
        value
      }
    }
  }
}
    `;
export const useTranslatablesQuery = <TData = Types.TranslatablesQuery, TError = unknown>(
  variables?: Types.TranslatablesQueryVariables,
  options?: UseQueryOptions<Types.TranslatablesQuery, TError, TData>,
) =>
  useQuery<Types.TranslatablesQuery, TError, TData>(
    variables === undefined ? ['translatables'] : ['translatables', variables],
    fetcher<Types.TranslatablesQuery, Types.TranslatablesQueryVariables>(
      TranslatablesDocument,
      variables,
    ),
    options,
  );
export const UserDocument = `
    query user($id: ID!) {
  user(id: $id) {
    ...user
  }
}
    ${UserFragmentDoc}
${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useUserQuery = <TData = Types.UserQuery, TError = unknown>(
  variables: Types.UserQueryVariables,
  options?: UseQueryOptions<Types.UserQuery, TError, TData>,
) =>
  useQuery<Types.UserQuery, TError, TData>(
    ['user', variables],
    fetcher<Types.UserQuery, Types.UserQueryVariables>(UserDocument, variables),
    options,
  );
export const UserActivityStreamDocument = `
    query userActivityStream($id: ID!, $limit: Int, $offset: Int, $sort: SortTypeInput, $date: String) {
  userActivityStream(
    id: $id
    limit: $limit
    offset: $offset
    sort: $sort
    date: $date
  ) {
    date
    total
    items {
      id
      actor_id
      user {
        ...userBasic
      }
      object_id
      object_type
      summary
      summary_template
      payload
      summary_vars
      created_at
    }
  }
}
    ${UserBasicFragmentDoc}`;
export const useUserActivityStreamQuery = <TData = Types.UserActivityStreamQuery, TError = unknown>(
  variables: Types.UserActivityStreamQueryVariables,
  options?: UseQueryOptions<Types.UserActivityStreamQuery, TError, TData>,
) =>
  useQuery<Types.UserActivityStreamQuery, TError, TData>(
    ['userActivityStream', variables],
    fetcher<Types.UserActivityStreamQuery, Types.UserActivityStreamQueryVariables>(
      UserActivityStreamDocument,
      variables,
    ),
    options,
  );
export const UserBasicDocument = `
    query userBasic($id: ID!) {
  user(id: $id) {
    ...userBasic
  }
}
    ${UserBasicFragmentDoc}`;
export const useUserBasicQuery = <TData = Types.UserBasicQuery, TError = unknown>(
  variables: Types.UserBasicQueryVariables,
  options?: UseQueryOptions<Types.UserBasicQuery, TError, TData>,
) =>
  useQuery<Types.UserBasicQuery, TError, TData>(
    ['userBasic', variables],
    fetcher<Types.UserBasicQuery, Types.UserBasicQueryVariables>(UserBasicDocument, variables),
    options,
  );
export const UserListDocument = `
    query userList($grid: UserGridInput) {
  users(grid: $grid) {
    total
    rows {
      ...userInTheList
    }
  }
}
    ${UserInTheListFragmentDoc}
${UserBasicFragmentDoc}`;
export const useUserListQuery = <TData = Types.UserListQuery, TError = unknown>(
  variables?: Types.UserListQueryVariables,
  options?: UseQueryOptions<Types.UserListQuery, TError, TData>,
) =>
  useQuery<Types.UserListQuery, TError, TData>(
    variables === undefined ? ['userList'] : ['userList', variables],
    fetcher<Types.UserListQuery, Types.UserListQueryVariables>(UserListDocument, variables),
    options,
  );
export const UserNotificationsDocument = `
    query userNotifications($grid: NotificationGridInput) {
  userNotifications(grid: $grid) {
    total
    total_unread
    rows {
      ...notification
    }
  }
}
    ${NotificationFragmentDoc}
${UserBasicFragmentDoc}`;
export const useUserNotificationsQuery = <TData = Types.UserNotificationsQuery, TError = unknown>(
  variables?: Types.UserNotificationsQueryVariables,
  options?: UseQueryOptions<Types.UserNotificationsQuery, TError, TData>,
) =>
  useQuery<Types.UserNotificationsQuery, TError, TData>(
    variables === undefined ? ['userNotifications'] : ['userNotifications', variables],
    fetcher<Types.UserNotificationsQuery, Types.UserNotificationsQueryVariables>(
      UserNotificationsDocument,
      variables,
    ),
    options,
  );
export const UserStatisticsDocument = `
    query userStatistics($id: ID!) {
  userStatistics(id: $id) {
    thisWeek
    overdue
    today
    open
    closed
  }
}
    `;
export const useUserStatisticsQuery = <TData = Types.UserStatisticsQuery, TError = unknown>(
  variables: Types.UserStatisticsQueryVariables,
  options?: UseQueryOptions<Types.UserStatisticsQuery, TError, TData>,
) =>
  useQuery<Types.UserStatisticsQuery, TError, TData>(
    ['userStatistics', variables],
    fetcher<Types.UserStatisticsQuery, Types.UserStatisticsQueryVariables>(
      UserStatisticsDocument,
      variables,
    ),
    options,
  );
export const UsersDocument = `
    query users($grid: UserGridInput) {
  users(grid: $grid) {
    rows {
      ...userBasic
    }
  }
}
    ${UserBasicFragmentDoc}`;
export const useUsersQuery = <TData = Types.UsersQuery, TError = unknown>(
  variables?: Types.UsersQueryVariables,
  options?: UseQueryOptions<Types.UsersQuery, TError, TData>,
) =>
  useQuery<Types.UsersQuery, TError, TData>(
    variables === undefined ? ['users'] : ['users', variables],
    fetcher<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, variables),
    options,
  );
export const UsersBasicDocument = `
    query usersBasic($grid: UserGridInput) {
  users(grid: $grid) {
    total
    rows {
      ...userBasic
    }
  }
}
    ${UserBasicFragmentDoc}`;
export const useUsersBasicQuery = <TData = Types.UsersBasicQuery, TError = unknown>(
  variables?: Types.UsersBasicQueryVariables,
  options?: UseQueryOptions<Types.UsersBasicQuery, TError, TData>,
) =>
  useQuery<Types.UsersBasicQuery, TError, TData>(
    variables === undefined ? ['usersBasic'] : ['usersBasic', variables],
    fetcher<Types.UsersBasicQuery, Types.UsersBasicQueryVariables>(UsersBasicDocument, variables),
    options,
  );
